import { create } from "zustand";

interface ClientProject {
	floorArea?: number;
	storeys?: number;
	name?: string;
	email?: string;
	message?: string;
	setEstimates: (floorArea?: number, storeys?: number) => void;
	setMessages: (name: string, email: string, message: string) => void;
	reset: () => void;
}

export const useProjects = create<ClientProject>((set, get) => ({
	floorArea: undefined,
	storeys: undefined,
	name: undefined,
	email: undefined,
	message: undefined,
	setEstimates: (floorArea?: number, storeys?: number) => {
		set({ floorArea, storeys });
	},
	setMessages: (name: string, email: string, message: string) => {
		set({
			name,
			email,
			message,
		});
	},
	reset: () => {
		set({
			floorArea: undefined,
			storeys: undefined,
			name: undefined,
			email: undefined,
			message: undefined,
		});
	},
}));
