import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import emailjs from "@emailjs/browser";
import { HelmetProvider } from "react-helmet-async";

import Navigation from "./navigation";

import "./utils/main";

const queryClient = new QueryClient();

function App() {
	emailjs.init("pBZOybsBz5vxoDwXA");

	return (
		<HelmetProvider>
			<React.StrictMode>
				<QueryClientProvider client={queryClient}>
					<Navigation />
				</QueryClientProvider>
			</React.StrictMode>
		</HelmetProvider>
	);
}

export default App;
