export const featuredSectionBulletSchema = {
	name: "featuredSectionBulletSchema",
	title: "Featured Section Bullet",
	type: "object",
	fields: [
		{
			name: "tagline",
			title: "Tag Line",
			description: "Tag line of section",
			type: "string",
		},
		{
			name: "header",
			title: "Header",
			description: "Title of the section",
			type: "string",
		},
		{
			name: "image",
			title: "Featured Image",
			type: "imageSchema",
		},
		{
			name: "content",
			title: "Body",
			type: "array",
			of: [{ type: "block" }],
		},
		{
			name: "entries",
			title: "Bullet Entries",
			description:
				"Please add up to 4 entries, exceeding entries will not be display",
			type: "array",
			of: [
				{
					name: "bullet",
					type: "object",
					min: 1,
					max: 4,
					fields: [
						{
							name: "bulletIcon",
							title: "Entry Icon",
							type: "iconSchema",
						},
						{
							name: "entry",
							title: "Entry",
							type: "array",
							of: [{ type: "block" }],
						},
					],
				},
			],
		},
	],
};

export const featuredSectionListSchema = {
	name: "featuredSectionListSchema",
	title: "Featured Section List",
	type: "object",
	fields: [
		{
			name: "tagline",
			title: "Tag Line",
			description: "Tag line of section",
			type: "string",
		},
		{
			name: "header",
			title: "Header",
			description: "Title of the section",
			type: "string",
		},
		{
			name: "image",
			title: "Featured Image",
			type: "imageSchema",
		},
		{
			name: "content",
			title: "Body",
			type: "array",
			of: [{ type: "block" }],
		},
		{
			name: "entries",
			title: "Entries",
			type: "array",
			of: [
				{
					name: "entry",
					type: "object",
					fields: [
						{
							name: "header",
							title: "Header",
							type: "string",
						},
						{
							name: "subHeader",
							title: "Sub Header",
							type: "string",
						},
					],
				},
			],
		},
	],
};

export const featuredProjectsSchema = {
	name: "featuredProjectsSchema",
	title: "Featured Projects",
	type: "object",
	fields: [
		{
			name: "header",
			title: "Header Title",
			type: "string",
		},
		{
			name: "description",
			title: "Description",
			type: "array",
			of: [{ type: "block" }],
		},
		{
			name: "projects",
			title: "Projects",
			description: "Select Projects, maximum and minimum of 3",
			type: "array",
			of: [
				{
					name: "projects",
					title: "Projects",
					type: "reference",
					to: [{ type: "projectSchema" }],
				},
			],
		},
	],
};

export const featuredBlogPostSchema = {
	name: "featuredBlogPostSchema",
	title: "Featured Blogs",
	type: "object",
	fields: [
		{
			name: "header",
			title: "Header Title",
			type: "string",
		},
		{
			name: "description",
			title: "Description",
			type: "array",
			of: [{ type: "block" }],
		},
		{
			name: "blogs",
			title: "Blosgs",
			description: "Select Blogs, maximum and minimum of 3",
			type: "array",
			of: [
				{
					name: "blogs",
					title: "Blogs",
					type: "reference",
					to: [{ type: "blogSchema" }],
				},
			],
		},
	],
};
