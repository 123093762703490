import { Contents, Image } from "../../interfaces/commonSanityInterfaces";

export const FetchBlogPayload = `
*[_type == 'blogSchema'] {
  _id,
  _createdAt,
  _updatedAt,
  header,
  tagline,
  headerImage {
    asset -> {
      _id,
      url 
    }
  },
  contents[]
}
`;

export interface BlogData {
	_id: string;
	headerImage: Image;
	header: string;
	_createdAt: Date;
	tagline?: string;
	contents?: Contents[];
	_updatedAt?: Date;
}
