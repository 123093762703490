import React, { useEffect } from "react";
import { useProjectDetail } from "./hooks";
import { Helmet } from "react-helmet-async";

import PortableTextArea from "../../components/portable-text";
import ContentImage from "../../components/content-image";
import LoadingView from "../../components/loading-view";

import style from "./style.module.css";
import * as main from "../../utils/main";

export const ProjecDetail = () => {
	const { name, headerBody, contents, headerImage, isLoading } =
		useProjectDetail();
	const headerStyle = [main.font.header2, style.header].join(" ");

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return isLoading ? (
		<LoadingView />
	) : (
		<>
			<Helmet>
				<title>{name}</title>
				<meta
					name="description"
					content="Welcome to GD-Studio"
					data-react-helmet="true"
				/>
				<meta
					property="og:title"
					content={`GD-Studio's ${name}`}
					data-react-helmet="true"
				/>
				<meta
					property="og:description"
					content="Welcome to GD-Studio"
					data-react-helmet="true"
				/>
				<meta
					property="og:image"
					content={headerImage?.asset.url}
					data-react-helmet="true"
				/>
			</Helmet>
			{headerImage && (
				<ContentImage
					style={style.cover}
					id={headerImage.asset._id ?? ""}
					alt={headerImage.altText ?? ""}
				/>
			)}
			<div className={style.coverOverlay}></div>
			<div className={style.coverOverlayBottom}></div>
			<div className={style.mainContainer}>
				<div className={style.body}>
					<p className={headerStyle}>{name}</p>
					{/* <p className={bodyStyle}>{description}</p> */}
					<PortableTextArea value={headerBody} normalStyle={style.bodyText} />
					<PortableTextArea
						value={contents}
						normalStyle={style.bodyText}
						bulletStyle={style.bodyText}
						numberedStyle={style.bodyText}
						h1Style={style.bodyText}
						h2Style={style.bodyText}
						h3Style={style.bodyText}
						h4Style={style.bodyText}
						h5Style={style.bodyText}
						h6Style={style.bodyText}
						blockqouteStyle={style.bodyText}
					/>

					{/* {images?.map((imageShowcase, index) => {
						return (
							<img
								className={style.showroom}
								src={imageShowcase}
								alt={`Showcase number ${index}`}
							/>
						);
					})} */}
				</div>
			</div>
		</>
	);
};

export default ProjecDetail;
