import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import style from "./style.module.css";
import Menu from "../components/menu";
import Footer from "../components/footer";

const RootLayout = () => {
	const location = useLocation();
	const isAdminRoute = location.pathname.includes("/admin");

	return (
		<div className={style.mainContainer}>
			{!isAdminRoute && <Menu />}
			<Outlet />
			{!isAdminRoute && <Footer />}
		</div>
	);
};

export default RootLayout;
