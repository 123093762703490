export const ProjectDetailedPayload = `*[_type == 'projectSchema' && _id == $id] {
  _id,
  headerTitle {
    headerTitle,
    subHeaderTitle,
    content[]
  },
  backgroundImage {
    asset -> {
      _id,
      url
    }
  },
  contents[] {
    ...,
    // For images within the imagesArray
    _type == "imagesArray" => {
      ...,
      "images": images[] {
        altText,
        asset -> {
          _id,
          url 
        }
      }
    }
  }
}`;
