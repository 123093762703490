import { NavLink } from "react-router-dom";
import { useWindowSize } from "../../modules/useWindowSize";
import { useMenu } from "./hook";

import style from "./style.module.css";
import { Icons } from "../../utils/Icons";
import { HeaderMenu } from "../../hooks/headerFooter/interfaces";
import ContentImage from "../content-image";

const Menu = () => {
	const { isMobileWidth } = useWindowSize();
	const {
		isMenuActive,
		setMenuState,
		desktopMenus,
		mobileMenus,
		goingHome,
		logo,
	} = useMenu();

	const _buildMenuItem = (item: HeaderMenu) => {
		return (
			<li className={style.menuList} key={`menu-${item.menuName}`}>
				<NavLink
					className={({ isActive }) =>
						isActive ? style.menuLinkSelected : style.menuLink
					}
					to={item.menuURL}
					onClick={() => setMenuState(false)}
				>
					{item.menuName}
				</NavLink>
			</li>
		);
	};

	const _buildMobileNavBar = () => {
		return (
			<nav className={style.mainContainer}>
				<div className={style.mobileContainer}>
					<img
						className={style.mobileNavIcon}
						src={
							isMenuActive ? Icons.closeButtonIcon : Icons.hamburgerButtonIcon
						}
						alt="GD-Studio Logo"
						height={25}
						onClick={() => {
							setMenuState(!isMenuActive);
							goingHome();
						}}
					/>
					<div className={style.mobileSeparatorLine} />
					{logo && (
						<ContentImage
							id={logo.asset._ref}
							alt={logo.altText}
							onClick={() => goingHome()}
							style={style.mobileNavLogo}
						/>
					)}
				</div>
				<div
					className={
						isMenuActive
							? style.mobileMenuContainerActive
							: style.mobileMenuContainer
					}
				>
					{mobileMenus?.map((item) => _buildMenuItem(item))}
				</div>
			</nav>
		);
	};

	const _buildDesktopNavBar = () => {
		return (
			<nav className={style.mainContainer}>
				<ul className={style.menus}>
					{desktopMenus.map((item) => {
						return item ? (
							_buildMenuItem(item)
						) : (
							<>
								<div className={style.lineSperator} />
								{logo && (
									<ContentImage
										id={logo.asset._ref}
										alt={logo.altText}
										onClick={() => goingHome()}
										style={style.gdLogo}
									/>
								)}
								<div className={style.lineSperator} />
							</>
						);
					})}
				</ul>
			</nav>
		);
	};

	return isMobileWidth ? _buildMobileNavBar() : _buildDesktopNavBar();
};

export default Menu;
