import { Employee } from "../../interfaces/pageContentSanityInterfaces";
import ContentImage from "../content-image";

import styles from "./style.module.css";

const EmployeeView: React.FC<Employee> = ({ profilePhoto, name, position }) => {
	return (
		<div className={styles.mainContainer}>
			<ContentImage
				id={profilePhoto.asset._ref}
				alt={profilePhoto.altText}
				style={styles.image}
			/>
			<h3 className={styles.name}>{name}</h3>
			<h5 className={styles.position}>{position}</h5>
		</div>
	);
};

export default EmployeeView;
