import { useEffect } from "react";
import { useBlogs } from "./hooks";

import BlogListView from "../../components/blog-list-view";
import BlogListViewHorizontal from "../../components/blog-list-view-horizontal";
import LoadingView from "../../components/loading-view";

import style from "./style.module.css";

const Blogs = () => {
	const { blogs, navigateTo, isMobileWidth, isLoading } = useBlogs();

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return isLoading ? (
		<LoadingView />
	) : (
		<div className={style.mainContainer}>
			{isMobileWidth ? (
				<div className={style.listView}>
					{blogs &&
						blogs.map((blog) => (
							<BlogListViewHorizontal
								{...blog}
								onClick={(blogID) => navigateTo(blogID)}
							/>
						))}
				</div>
			) : (
				<div className={style.gridView}>
					{blogs &&
						blogs.map((blog) => (
							<BlogListView
								onClick={(blogID: string) => navigateTo(blogID)}
								{...blog}
							/>
						))}
				</div>
			)}
		</div>
	);
};

export default Blogs;
