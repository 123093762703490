import { useQuery } from "@tanstack/react-query";
import Client from "../../sanityClient";

import { AboutUsPayload } from "./interfaces";
import { PageContentData } from "../../interfaces/pageContentSanityInterfaces";
import { useMemo } from "react";

const fetchAboutUsPayload = async () => {
	try {
		const result = await Client.fetch(AboutUsPayload);
		return result.length > 0 ? (result[0] as PageContentData) : undefined;
	} catch (error) {
		throw error;
	}
};

export const useAboutUs = () => {
	const { data, isLoading } = useQuery({
		queryKey: ["about-us"],
		queryFn: fetchAboutUsPayload,
	});

	const headerImage = useMemo(() => {
		return data?.headerImage ?? undefined;
	}, [data]);

	const headerTitle = useMemo(() => {
		return data?.body?.headerTitle;
	}, [data]);

	const tagline = useMemo(() => {
		return data?.body?.subHeaderTitle;
	}, [data]);

	const body = useMemo(() => {
		return data?.body?.content;
	}, [data]);

	return {
		headerImage,
		headerTitle,
		tagline,
		body,
		isLoading,
	};
};
