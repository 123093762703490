import React from "react";
import { useEffect } from "react";
import { useProjects } from "./hooks";

// components
import ProjectListView from "../../components/project-list-view";
import LoadingView from "../../components/loading-view";

// utilities
import "../../utils/main";
import style from "./style.module.css";

const Projects = () => {
	const { contentData, isLoading } = useProjects();

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return isLoading ? (
		<LoadingView />
	) : (
		<>
			<div className={style.gridView}>
				{contentData &&
					contentData.map((data) => <ProjectListView {...data} />)}
			</div>
		</>
	);
};

export default Projects;
