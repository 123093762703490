import React from "react";
import { useWindowSize } from "../../modules/useWindowSize";

import ContentImage from "../content-image";
import PortableTextArea from "../portable-text";

import styles from "./style.module.css";
import { Content, Entry } from "../../interfaces/pageContentSanityInterfaces";
import { FeaturedBulletList } from "../../interfaces/featuredBulletList";

const FeatureSectionList: React.FC<Content | FeaturedBulletList> = ({
	tagline,
	header,
	image,
	content,
	entries,
}) => {
	const { isMobileWidth, windowSize } = useWindowSize();

	return (
		<div className={styles.mainContainer}>
			{!isMobileWidth && (
				<div className={styles.imageContainer}>
					{image && (
						<ContentImage
							id={image?.asset._ref}
							alt={image?.altText}
							crop={image.crop}
							hotSpot={image.hotspot}
							style={styles.image}
							mode="cover"
							width={windowSize.width * 0.3}
							height={windowSize.height * 0.5}
						/>
					)}
				</div>
			)}
			<div className={styles.textContainer}>
				{image && isMobileWidth && (
					<ContentImage
						id={image?.asset._ref}
						alt={image?.altText}
						style={styles.image}
					/>
				)}
				<h4 className={styles.tagline}>{tagline}</h4>
				<h2 className={styles.header}>{header}</h2>
				<PortableTextArea
					value={content}
					normalStyle={styles.bulletedText}
					h1Style={styles.textColor}
					h2Style={styles.textColor}
					h3Style={styles.textColor}
					h4Style={styles.textColor}
					h5Style={styles.textColor}
					h6Style={styles.textColor}
					linkStyle={styles.textColor}
					bulletStyle={styles.textColor}
					numberedStyle={styles.textColor}
					blockqouteStyle={styles.textColor}
				/>
				<div className={styles.entriesContainer}>
					{entries?.map((entry) => (
						<div className={styles.entryContainer}>
							<h3 className={styles.entryHeader}>{entry.header}</h3>
							<p className={styles.entrySubHeader}>{entry.subHeader}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default FeatureSectionList;
