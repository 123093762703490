import { useNavigate } from "react-router-dom";
import { useHeaderFooterStore } from "../../hooks/headerFooter";
import { useMemo } from "react";

export const useFooter = () => {
	const navigate = useNavigate();
	const { contentData } = useHeaderFooterStore();
	const facebookLink = useMemo(() => {
		return contentData && contentData.facebookLink.isEnable
			? contentData?.facebookLink
			: undefined;
	}, [contentData]);

	const instagramLink = useMemo(() => {
		return contentData && contentData.instagramLink.isEnable
			? contentData?.instagramLink
			: undefined;
	}, [contentData]);

	const tiktokLink = useMemo(() => {
		return contentData && contentData.tiktokLink.isEnable
			? contentData?.tiktokLink
			: undefined;
	}, [contentData]);

	const navigateEstimate = () => {
		navigate("estimate/");
	};

	const menus = useMemo(() => {
		return contentData?.headerMenus ?? [];
	}, [contentData]);

	const freeEstimateLabel = useMemo(() => {
		return contentData?.freeEstimateCTA ?? "";
	}, [contentData]);

	const footerLabel = useMemo(() => {
		return contentData?.footerDescription;
	}, [contentData]);

	const logoImage = useMemo(() => {
		return contentData?.footerSiteLogo;
	}, [contentData]);

	const goingHome = () => {
		navigate("/");
	};

	return {
		freeEstimateLabel,
		menus,
		facebookLink,
		tiktokLink,
		instagramLink,
		navigateEstimate,
		footerLabel,
		logoImage,
		goingHome,
	};
};
