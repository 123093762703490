import type { Project } from "./project";

const dlLakeHouse: Project = {
	name: "DL Lakehouse Rizal",
	briefDescription:
		"As the light draws indirectly towards the interior of the home, it gives emphasis to the industrial and beton brut style through the combination of material textures of concrete, wood and metal.",
	fullDescription:
		"As the light draws indirectly towards the interior of the home, it gives emphasis to the industrial and beton brut style through the combination of material textures of concrete, wood and metal. Though just a simple open plan, with proper layout and strategic fenestration, the lakehouse turns out to be a very homey and relaxing place to live in. \n This project consists of four (4) prefabricated units as the bedrooms, then reconstructed with a common area in the center to make a large bungalow house. A strategic plan to maximize the budget for a bungalow, and at the same time, consider the accessibility. It is also planned and studied for all areas in the house to give full access to the lake view and allow natural light and ventilation.",
	headerImage:
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149779/gd-studio/projects/dl%20lakehouse%20rizal/uyw5awa7ryeneyoaqze5.jpg",
	images: [
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149778/gd-studio/projects/dl%20lakehouse%20rizal/gp5ee9cpipj5lvvmzqap.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149778/gd-studio/projects/dl%20lakehouse%20rizal/xwi3hhbcr4aizsymmsch.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149778/gd-studio/projects/dl%20lakehouse%20rizal/vsbdgelqsmxq6fozehfw.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149778/gd-studio/projects/dl%20lakehouse%20rizal/dpq3ayvs8v1f3657s5e1.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149778/gd-studio/projects/dl%20lakehouse%20rizal/zxb7pcwxqbfcjuk0txtm.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149778/gd-studio/projects/dl%20lakehouse%20rizal/jhfzujxu6btphnpdxdwy.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149777/gd-studio/projects/dl%20lakehouse%20rizal/ulgb8kmclyg8q4tapjgk.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149777/gd-studio/projects/dl%20lakehouse%20rizal/jhh0ekzemzny4nozwvfg.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149777/gd-studio/projects/dl%20lakehouse%20rizal/finysnb8ahihiji28bfd.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149777/gd-studio/projects/dl%20lakehouse%20rizal/aew8csux1oaja3vol3ng.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149777/gd-studio/projects/dl%20lakehouse%20rizal/eebapkwi1td23ndmwhks.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149777/gd-studio/projects/dl%20lakehouse%20rizal/ebjmywgn3jfgcfrk1fzs.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149776/gd-studio/projects/dl%20lakehouse%20rizal/lxjbj6eu2dmioioempys.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149776/gd-studio/projects/dl%20lakehouse%20rizal/h5ikkbqdct9ymo05jgzv.jpg",
	],
};

const lDuplexParanaque: Project = {
	name: "L-Duplex Paranaque",
	briefDescription:
		"The right amount of simplicity, utility and elegance is the approach used in the whole architectural experience.",
	fullDescription:
		"The right amount of simplicity, utility and elegance is the approach used in the whole architectural experience. Minimal and toned down colors for light and airy feeling in addition to the small but open plan with cross ventilation treatment. The flexible spaces are also considered on this project to make it more useful for a wide range of target users.",
	headerImage:
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149877/gd-studio/projects/l%20duplex%20paranaque/joh33fykhubjwgo3pzgh.jpg",
	images: [
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149878/gd-studio/projects/l%20duplex%20paranaque/vyyqxg4k9yhpscownwis.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149877/gd-studio/projects/l%20duplex%20paranaque/mxvztd1fakpoqffpteaq.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149877/gd-studio/projects/l%20duplex%20paranaque/c13zwilnx8hykvxpzxd2.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149877/gd-studio/projects/l%20duplex%20paranaque/gxe6fekjeevl1iuo6ukd.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149877/gd-studio/projects/l%20duplex%20paranaque/fpl0qguly349zla76k9e.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149877/gd-studio/projects/l%20duplex%20paranaque/bnhwwrs5jrmzlfmq2yje.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149877/gd-studio/projects/l%20duplex%20paranaque/lhtx1j0qew1v6dipfuhn.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696149877/gd-studio/projects/l%20duplex%20paranaque/mdumxzqjb0lxfjg8trqv.jpg",
	],
};

const cResidenceBulacan: Project = {
	name: "C-Residence Bulacan",
	briefDescription:
		"An economical but uncompromised elegance through the natural finishes, neutral colors and strategic lighting on the facade to the flexible design on the interiors.",
	fullDescription:
		"An economical but uncompromised elegance through the natural finishes, neutral colors and strategic lighting on the facade to the flexible design on the interiors. The grid layout of the interior makes all the spaces more useful for the wide range of target users especially that this design project is specifically for build-and-sell. The interiors can be flexible through easy modification of drywall partitions based on the needs and preferences of end users.",
	headerImage:
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696147414/gd-studio/projects/c-residence%20bulacan/eosnekyueuwl1fcu8fkl.jpg",
	images: [
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696147414/gd-studio/projects/c-residence%20bulacan/nixibcckkfi6odxfbuof.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696147414/gd-studio/projects/c-residence%20bulacan/iqnebwxjhdyqpyotcjdw.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696147417/gd-studio/projects/c-residence%20bulacan/dyad4gbghins6xwms9ga.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696147415/gd-studio/projects/c-residence%20bulacan/paipdadw550bbrmxocf4.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696147415/gd-studio/projects/c-residence%20bulacan/vnegsl9hha459xjfsdai.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696147415/gd-studio/projects/c-residence%20bulacan/hujn6a8c5fahcdz8idrw.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696147414/gd-studio/projects/c-residence%20bulacan/w0sxnhtwspytbyzi80pr.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696147414/gd-studio/projects/c-residence%20bulacan/c7ehv0vt0ykvznvsybdx.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1696147414/gd-studio/projects/c-residence%20bulacan/dqriciaimwntrxbpaef3.jpg",
	],
};

const casaJose: Project = {
	name: "Casa Jose",
	briefDescription: "",
	fullDescription: "",
	headerImage:
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704586964/gd-studio/projects/casa%20jose/rud1e9kspgfmf2nmzbua.jpg",
	images: [
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466292/gd-studio/projects/casa%20jose/tfkvq2owg7qrxk9zqqpq.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466291/gd-studio/projects/casa%20jose/vqlthowe22daudjuuwgt.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466291/gd-studio/projects/casa%20jose/wdbxtqgvdvvp5qqxleqv.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466291/gd-studio/projects/casa%20jose/pf9c2vzo87jnv6dpxpah.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466291/gd-studio/projects/casa%20jose/lovinte6tdokmpdmcg9p.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466291/gd-studio/projects/casa%20jose/sxotodzrrpqg4irlaxu1.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466291/gd-studio/projects/casa%20jose/bng0kctjpp1ysug6egbs.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466290/gd-studio/projects/casa%20jose/ew9u5l8d94rcid8gn3wm.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704586964/gd-studio/projects/casa%20jose/rud1e9kspgfmf2nmzbua.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466290/gd-studio/projects/casa%20jose/vbuymvgodtxtw7fndeyj.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466290/gd-studio/projects/casa%20jose/rd71pdpl7xzs02wofsee.jpg",
	],
};

const casaMuloy: Project = {
	name: "Casa Muloy",
	briefDescription: "",
	fullDescription: "",
	headerImage:
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466337/gd-studio/projects/casa%20muloy/zhqykxgy1vgfpvhcvir1.jpg",

	images: [
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466339/gd-studio/projects/casa%20muloy/c8t5fa7u396lzce8wda1.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466338/gd-studio/projects/casa%20muloy/g9e5gnz0bsweejmergjt.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466338/gd-studio/projects/casa%20muloy/ysmjv9tyg3tkwy0jursd.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466338/gd-studio/projects/casa%20muloy/atd5jgditwykz1dbipi7.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466338/gd-studio/projects/casa%20muloy/kjsrig5x6t4scsoxqqpc.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466338/gd-studio/projects/casa%20muloy/vafkzhnilyvqyltgm3py.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466337/gd-studio/projects/casa%20muloy/wpymu2zb3ulmkphmznpx.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466337/gd-studio/projects/casa%20muloy/bvb40dbneclvytmjgors.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466337/gd-studio/projects/casa%20muloy/zhqykxgy1vgfpvhcvir1.jpg",
	],
};

const casitaPuraw: Project = {
	name: "Casita Puraw",
	briefDescription: "",
	fullDescription: "",
	headerImage:
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466890/gd-studio/projects/casita%20puraw/wrc56qnoqhxumh3ohlbv.jpg",
	images: [
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466890/gd-studio/projects/casita%20puraw/tbjvpvajznqalmqghsea.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466889/gd-studio/projects/casita%20puraw/zxikcqgmxryk8s3kvn1h.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466888/gd-studio/projects/casita%20puraw/wy7kdpq6jukqfwsggnmw.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466888/gd-studio/projects/casita%20puraw/rgq6i5mwm3eyqblvypmw.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466888/gd-studio/projects/casita%20puraw/kwdi08zlh5tg514peolq.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466887/gd-studio/projects/casita%20puraw/t28itg37svxusvdajpx3.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466887/gd-studio/projects/casita%20puraw/dhi4xkyxqxqexonjg29x.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704466887/gd-studio/projects/casita%20puraw/fthsj6myrrsqqasck9ng.jpg",
	],
};

const laCasaDePotpot: Project = {
	name: "La Casa De Potpot",
	briefDescription: "",
	fullDescription: "",
	headerImage:
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535243/gd-studio/projects/la%20casa%20de%20potpot/vpgxy3idqhjja6qf07da.jpg",
	images: [
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535244/gd-studio/projects/la%20casa%20de%20potpot/ghaijhwswnavzf2wg0wr.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1705967434/gd-studio/projects/la%20casa%20de%20potpot/dmbz7hckvhhdktooghn8.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535243/gd-studio/projects/la%20casa%20de%20potpot/vpgxy3idqhjja6qf07da.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535242/gd-studio/projects/la%20casa%20de%20potpot/lfwulm7fygncanibofik.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535242/gd-studio/projects/la%20casa%20de%20potpot/kmtniptmx4gujqhklcih.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535242/gd-studio/projects/la%20casa%20de%20potpot/xbezchkvk4guz5zkssxf.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535242/gd-studio/projects/la%20casa%20de%20potpot/iiy4ykxsxro38oakpk0k.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535241/gd-studio/projects/la%20casa%20de%20potpot/kt1qba0fpbtgmse6clvo.jpg",
	],
};

const mFarmhouse: Project = {
	name: "M-Farmhouse",
	briefDescription: "",
	fullDescription: "",
	headerImage:
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535909/gd-studio/projects/m%20farmhouse/gt3cx2skpzvoxgex3du9.jpg",
	images: [
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535909/gd-studio/projects/m%20farmhouse/tvjydvrdaqod2fb7rnda.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535909/gd-studio/projects/m%20farmhouse/swg28adoakrljszeys2j.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535909/gd-studio/projects/m%20farmhouse/ddsdwpd7u5s2zug637ql.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535910/gd-studio/projects/m%20farmhouse/f5gknrkvibqa6c6k7bax.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535910/gd-studio/projects/m%20farmhouse/skjmn6w6wh0tly4st410.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535911/gd-studio/projects/m%20farmhouse/mnq1eppvf4yyjgirnpax.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535911/gd-studio/projects/m%20farmhouse/jqyuwlpa6iiehjfkgnnh.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535911/gd-studio/projects/m%20farmhouse/ekt3iazpydxgmaya7txm.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704535911/gd-studio/projects/m%20farmhouse/uranhcia7nyubdsaqpha.jpg",
	],
};

const vHouse: Project = {
	name: "V House",
	briefDescription: "",
	fullDescription: "",
	headerImage:
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704536137/gd-studio/projects/v%20house/jzlvsbjcos9bk1lwbzyi.jpg",
	images: [
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704536138/gd-studio/projects/v%20house/mfh55rqeetd1ryrihljs.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704536137/gd-studio/projects/v%20house/jzlvsbjcos9bk1lwbzyi.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704536137/gd-studio/projects/v%20house/ouhbocroyncinknrxmuv.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704536137/gd-studio/projects/v%20house/ovb07vakl5dft6xfvb5w.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704536137/gd-studio/projects/v%20house/y7xkxxqtxgkq8ocdtmst.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704536136/gd-studio/projects/v%20house/oyauucjaey3ukz6vgfzq.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704536136/gd-studio/projects/v%20house/rlgympsd8jz0248ailrf.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1704536135/gd-studio/projects/v%20house/uc5v0nyflqevyo35zmmu.jpg",
	],
};

export const projects: Project[] = [
	casaJose,
	casaMuloy,
	casitaPuraw,
	laCasaDePotpot,
	mFarmhouse,
	vHouse,
];
