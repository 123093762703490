import React, { ReactNode } from "react";

import PortableTextArea from "../portable-text";

import * as mainUtils from "../../utils/main";
import styles from "./styles.module.css";
import { InfoElement } from "../../interfaces/pageContentSanityInterfaces";

interface FormInterface {
	headerTitle?: string;
	subHeader?: InfoElement[];
	children: ReactNode;
}

const Forms: React.FC<FormInterface> = ({
	headerTitle,
	subHeader,
	children,
}) => {
	return (
		<div className={styles.mainContainer}>
			<div className={styles.contentContainer}>
				<h1 className={styles.headerTitle}>{headerTitle}</h1>
				<PortableTextArea value={subHeader ?? []} normalStyle={styles.notes} />
				{children}
			</div>
		</div>
	);
};

export default Forms;
