export const contactFormSectionSchema = {
	name: "contactFormSectionSchema",
	title: "Contact Section",
	type: "object",
	fields: [
		{
			name: "header",
			title: "Header Title",
			type: "string",
		},
		{
			name: "subHeader",
			title: "Sub Header",
			type: "array",
			of: [{ type: "block" }],
		},
		{
			name: "nameField",
			title: "Name Field Label",
			type: "string",
		},
		{
			name: "emailField",
			title: "Email Field Label",
			type: "string",
		},
		{
			name: "phoneField",
			title: "Phone Field Label",
			type: "string",
		},
		{
			name: "messageField",
			title: "Message Field Label",
			type: "string",
		},
		{
			name: "submitCTA",
			title: "Submit CTA Label",
			type: "string",
		},
		{
			name: "successMessage",
			title: "Success Message",
			type: "array",
			of: [{ type: "block" }],
		},
		{
			name: "successMessageTitle",
			title: "Sucess Message Title",
			type: "string",
		},
		{
			name: "backButtonLabel",
			title: "Back To Home Label",
			type: "string",
		},
	],
};
