import { useBlogDetail } from "./hooks";
import { useWindowSize } from "../../modules/useWindowSize";

import ContentImage from "../../components/content-image";
import PortableTextArea from "../../components/portable-text";
import LoadingView from "../../components/loading-view";

import styles from "./style.module.css";
import { useMemo, useEffect } from "react";

const BlogDetail = () => {
	const { tagline, header, headerImage, postedDate, contents, isLoading } =
		useBlogDetail();
	const { windowSize, isMobileWidth, staticWindowSize } = useWindowSize();
	const size = useMemo(() => {
		const height = !isMobileWidth
			? (staticWindowSize.current?.height ?? 0 * 0.45)
			: windowSize.height * 0.3;

		const width = isMobileWidth
			? (staticWindowSize.current?.width ?? 0 * 0.5)
			: windowSize.width * 0.5;

		return { height, width };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobileWidth]);

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return isLoading ? (
		<LoadingView />
	) : (
		<div className={styles.mainContainer}>
			<div className={styles.contentContainer}>
				<div className={styles.headlineContainer}>
					<h4 className={styles.tagline}>{tagline}</h4>
					<h2 className={styles.headlineTitle}>{header}</h2>
					<h5 className={styles.datePosted}>{postedDate}</h5>
				</div>
				<div className={styles.headerImageContainer}>
					{headerImage && (
						<ContentImage
							id={headerImage.asset._id ?? ""}
							alt={headerImage.altText}
							crop={headerImage.crop}
							hotSpot={headerImage.hotspot}
							mode="cover"
							height={size.height}
							width={size.width}
							style={styles.headerImage}
						/>
					)}
				</div>
				<PortableTextArea
					value={contents}
					normalStyle={styles.bodyText}
					bulletStyle={styles.bodyText}
					numberedStyle={styles.bodyText}
					h1Style={styles.bodyText}
					h2Style={styles.bodyText}
					h3Style={styles.bodyText}
					h4Style={styles.bodyText}
					h5Style={styles.bodyText}
					h6Style={styles.bodyText}
					blockqouteStyle={styles.bodyText}
				/>
			</div>
		</div>
	);
};

export default BlogDetail;
