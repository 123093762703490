import * as main from "../../utils/main";
import { useEffect } from "react";
import { useHome } from "./hooks";

import PortableTextArea from "../../components/portable-text";
import LoadingView from "../../components/loading-view";

import style from "./style.module.css";

const Home = () => {
	const { headerImage, headerTitle, body, isLoading } = useHome();

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<div className={style.mainContainers}>
			{isLoading ? (
				<LoadingView />
			) : (
				<>
					<div className={style.welcomeContainer}>
						{headerImage && (
							<img
								src={headerImage.asset.url}
								alt={headerImage.altText}
								className={style.homeCover}
							/>
						)}
						<h1 className={[main.font.header1, style.welcomeHeader].join(" ")}>
							{headerTitle}
						</h1>
					</div>
					<div className={style.content}>
						<PortableTextArea
							value={body ?? []}
							normalStyle={style.welcomeBody}
							h1Style={style.headercolor}
							h2Style={style.headercolor}
							h3Style={style.headercolor}
							h4Style={style.headercolor}
							h5Style={style.headercolor}
							h6Style={style.headercolor}
							bulletStyle={style.welcomeBody}
							numberedStyle={style.welcomeBody}
							blockqouteStyle={style.welcomeBody}
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default Home;
