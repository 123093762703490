import { useQuery } from "@tanstack/react-query";
import Client from "../../sanityClient";

import { ProjectDetailedPayload } from "./interfaces";
import { ProjectData } from "../../pages/projects/interfaces";

const fetchProject = async (id: string) => {
	try {
		const result = await Client.fetch(ProjectDetailedPayload, { id });
		return result.length > 0 ? (result[0] as ProjectData) : undefined;
	} catch (error) {
		throw error;
	}
};

export const useProject = (id: string) => {
	const { data, isLoading } = useQuery({
		queryKey: ["project-fetch", id],
		queryFn: () => fetchProject(id),
	});

	return {
		data,
		isLoading,
	};
};
