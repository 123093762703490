import { useFeaturedProjects } from "./hooks";

import PortableTextArea from "../portable-text";

import style from "./style.module.css";
import { FeaturedProjects as Props } from "../../interfaces/featuredProjects";
import ProjectListView from "../project-list-view";

const FeaturedProjects: React.FC<Props> = (projectProps: Props) => {
	const { data } = useFeaturedProjects(projectProps);

	return (
		<div className={style.mainContainer}>
			<h2 className={style.header}>{projectProps.header}</h2>
			<PortableTextArea
				value={projectProps.description}
				normalStyle={style.description}
				bulletStyle={style.description}
				numberedStyle={style.description}
				h1Style={style.description}
				h2Style={style.description}
				h3Style={style.description}
				h4Style={style.description}
				h5Style={style.description}
				h6Style={style.description}
				blockqouteStyle={style.bodyText}
			/>
			<div className={style.projects}>
				{data?.map((item) => <ProjectListView {...item} />)}
			</div>
		</div>
	);
};

export default FeaturedProjects;
