import { useEffect } from "react";
import { useEstimate } from "../../components/estimate-view/hook";

import EstimateView from "../../components/estimate-view/";
import ContentImage from "../../components/content-image";

import style from "./style.module.css";

const InitialEstimate = () => {
	const { data } = useEstimate();

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<div className={style.mainContainer}>
			{data?.headerImage && (
				<ContentImage
					id={data.headerImage.asset._ref}
					alt={data.headerImage.altText}
					style={style.background}
				/>
			)}

			<EstimateView />
		</div>
	);
};

export default InitialEstimate;
