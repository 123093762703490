import React from "react";

import * as mainUtils from "../../utils/main";
import styles from "./style.module.css";

interface Props {
	label?: string;
	onValueChange?: (val: string) => void;
}

const TextView: React.FC<Props> = ({ label, onValueChange }) => {
	return (
		<div className={styles.mainContainer}>
			<p className={styles.fieldLabel}>{label}</p>
			<div className={styles.inputContainter}>
				<textarea
					className={styles.input}
					onChange={(event) =>
						onValueChange && onValueChange(event.target.value)
					}
				/>
			</div>
		</div>
	);
};

export default TextView;
