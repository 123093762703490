import React, { useEffect, useMemo, useState } from "react";
import emailjs from "@emailjs/browser";
import { useProjects } from "../../stores/useProject";
import { useNavigate } from "react-router-dom";
import Client from "../../sanityClient";
import { useQuery } from "@tanstack/react-query";

import { EstimateModel } from "../../models/estimatesModel";
import { MessageModel } from "../../models/messageModel";
import { projects } from "../../models/projectData";
import { ContactUsContentPayload } from "./interfaces";
import { PageContentData } from "../../interfaces/pageContentSanityInterfaces";
import { SupportedFeaturedSchema } from "../../client/supportedComponentSchema";
import {
	ContactUs,
	ContactUs as ContactUsProps,
} from "../../interfaces/ContactUs";

const fetchContactUsData = async () => {
	try {
		const result = await Client.fetch(ContactUsContentPayload);
		return result.length > 0 ? (result[0] as PageContentData) : undefined;
	} catch (err) {
		throw err;
	}
};

export const useContactUs = () => {
	const { floorArea, storeys, reset } = useProjects();
	const [fullName, setFullName] = useState<string>();
	const [email, setEmail] = useState<string>();
	const [message, setMessage] = useState<string>();
	const [phoneNumber, setPhoneNumber] = useState<string>();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isMessageSent, setMessageSent] = useState<boolean>(false);
	const navigate = useNavigate();

	const { data, isLoading: isFetchingContactUsData } = useQuery({
		queryKey: ["contact-us-data"],
		queryFn: fetchContactUsData,
	});

	useEffect(() => {
		// sendEmail();
		window.scroll(0, 0);
	}, []);

	const randomizedBackground = useMemo((): string => {
		return projects[Math.floor(Math.random() * 5 + 0)].headerImage;
	}, []);

	const headerTitle = useMemo(() => {
		return data?.body?.headerTitle;
	}, [data]);

	const contentData = useMemo(() => {
		return data?.body?.content[0] as any as ContactUsProps;
	}, [data]);

	const subHeader = useMemo(() => {
		return contentData ? contentData.subHeader : undefined;
		// return floorArea && storeys!
		// 	? "Let us get additional info of yours before we send it to our Architects"
		// 	: "Hi there! If you have any inquiries, let's talk about it!";
	}, [contentData]);

	const sendEmailEstimate = async () => {
		if (floorArea && storeys && fullName && email && phoneNumber) {
			const templateParams: EstimateModel = {
				full_name: fullName,
				user_email: email,
				floor_number: String(storeys),
				sqm: String(floorArea),
				message: message,
				phone_number: phoneNumber,
			};

			try {
				const email = await emailjs.send(
					"service_ohjkxw7",
					"template_3m25umc",
					templateParams as Record<string, unknown>,
					"pBZOybsBz5vxoDwXA"
				);

				onResult(email.status);
			} catch (error) {
				alert("Something went wrong, please try again");
				setLoading(false);
			}
		} else {
			alert("Please fill up forms");
			setLoading(false);
			return;
		}
	};

	const sendEmailMessageOnly = async () => {
		if (fullName && email && phoneNumber) {
			const templateParams: MessageModel = {
				full_name: fullName,
				user_email: email,
				phone_number: phoneNumber,
				message,
			};

			try {
				const email = await emailjs.send(
					"service_ohjkxw7",
					"template_ad9zs9l",
					templateParams as Record<string, unknown>,
					"pBZOybsBz5vxoDwXA"
				);

				onResult(email.status);
			} catch (error) {
				alert("Something went wrong, please try again");
				setLoading(false);
			}
		} else {
			alert("Please fill up forms");
			setLoading(false);
			return;
		}
	};

	const onSubmit = () => {
		setLoading(true);

		if (floorArea && storeys) {
			sendEmailEstimate();
		} else {
			sendEmailMessageOnly();
		}
	};

	const onHome = () => {
		reset();
		navigate("/");
	};

	const onResult = (status: number) => {
		setLoading(false);
		window.scroll(0, 0);

		if (status === 200) {
			setMessageSent(true);
		} else {
			alert("Something went wrong, please try again");
		}
	};

	return {
		isLoading: isLoading || isFetchingContactUsData,
		isMessageSent,
		randomizedBackground,
		sendEmailEstimate,
		sendEmailMessageOnly,
		setPhoneNumber,
		subHeader,
		onSubmit,
		setEmail,
		setMessage,
		setFullName,
		onHome,
		contentData,
		data,
		headerTitle,
	};
};
