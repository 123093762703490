import React, { useState } from "react";
import { useProjectListView } from "./hooks";
import style from "./style.module.css";

// utilities
import "../../utils/main";
import { FeaturedProjectData } from "../featured-projects/interfaces";
import { ProjectData } from "../../pages/projects/interfaces";

const ProjectListView: React.FC<ProjectData | FeaturedProjectData> = ({
	headerTitle,
	backgroundImage,
	_id: id,
}) => {
	const { navigateTo, isHovered, setHover } = useProjectListView();

	const imageBackgroundStyle = {
		backgroundImage: `url(${backgroundImage.asset.url})`,
	};

	return (
		<div className={style.container} onClick={() => navigateTo(id)}>
			<div
				className={style.mainContainer}
				style={imageBackgroundStyle}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			></div>
			<div
				className={
					isHovered ? style.contentContainerHovered : style.contentContainer
				}
			>
				<h3 className={style.title}>{headerTitle.headerTitle}</h3>
				<p className={style.description}>{headerTitle.subHeaderTitle}</p>
			</div>
		</div>
	);
};

export default ProjectListView;
