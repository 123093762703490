import { useNavigate } from "react-router-dom";

import { PortableText, PortableTextReactComponents } from "@portabletext/react";
import ContentImage from "../content-image";
import CTA from "../cta";
import ProjectAttached from "../project-attachement";
import EmployeeSection from "../employees-section";
import FeatureSectionBullet from "../featuredSectionBullet";
import FeatureSectionList from "../featureSectionList";
import FeaturedProjects from "../featured-projects";
import GroupImagesCollage from "../group-images";
import FeaturedBlogs from "../featured-blogs";

import style from "./style.module.css";
import { TeamSection } from "../../interfaces/teamSection";
import { FeaturedBulletList } from "../../interfaces/featuredBulletList";
import { FeaturedProjects as FeaturedProjectProps } from "../../interfaces/featuredProjects";
import { GroupImage } from "../../interfaces/groupImages";
import { FeaturedBlogs as FeaturedBlogsProps } from "../../interfaces/featuredBlogs";

export interface Props {
	value?: any[];
	imageStyle?: string;
	normalStyle?: string;
	h1Style?: string;
	h2Style?: string;
	h3Style?: string;
	h4Style?: string;
	h5Style?: string;
	h6Style?: string;
	strongStyle?: string;
	linkStyle?: string;
	bulletStyle?: string;
	numberedStyle?: string;
	blockqouteStyle?: string;
}

const PortableTextArea: React.FC<Props> = ({
	value,
	imageStyle,
	normalStyle,
	h1Style,
	h2Style,
	h3Style,
	h4Style,
	h5Style,
	h6Style,
	strongStyle,
	linkStyle,
	bulletStyle,
	numberedStyle,
	blockqouteStyle,
}) => {
	const navigate = useNavigate();

	const overrideComponent: Partial<PortableTextReactComponents> = {
		types: {
			// Customize how images are rendered
			images: ({ value }) => {
				return (
					<ContentImage
						id={value.asset._ref}
						alt={value.altText || "Image"}
						crop={value.crop}
						hotSpot={value.hotSpot}
						mode="cover"
						height={500}
						style={imageStyle ?? style.image}
						onClick={() => navigate(value.asset.url, { replace: true })}
					/>
				);
			},
			cta: ({ value }) => {
				return (
					<CTA
						message={value.ctaMessage}
						bookButtonLabel={value.ctaBookButtonLabel}
						estimateButtonLabel={value.ctaEstimateButtonLabel}
					/>
				);
			},
			projects: ({ value }) => {
				return <ProjectAttached id={value._ref} />;
			},
			teams: ({ value }) => {
				const data = (value as TeamSection) ?? undefined;
				if (data) {
					return (
						<EmployeeSection
							headerTitle={data.header}
							subHeader={data.description}
							employees={data.employees}
						/>
					);
				} else {
					return <></>;
				}
			},
			featuredBullet: ({ value }) => {
				const data = (value as FeaturedBulletList) ?? undefined;
				if (data) {
					return <FeatureSectionBullet {...data} />;
				} else {
					return <></>;
				}
			},
			featuredList: ({ value }) => {
				const data = (value as FeaturedBulletList) ?? undefined;
				if (data) {
					return <FeatureSectionList {...data} />;
				} else {
					return <></>;
				}
			},
			featuredProjects: ({ value }) => {
				const data = (value as FeaturedProjectProps) ?? undefined;
				if (data) {
					return <FeaturedProjects {...data} />;
				} else {
					return <></>;
				}
			},
			imagesArray: ({ value }) => {
				console.log("TG>>>> value", value);
				const data = (value as GroupImage) ?? undefined;
				if (data) {
					return <GroupImagesCollage {...data} />;
				} else {
					return <></>;
				}
			},
			featuredBlogs: ({ value }) => {
				const data = (value as FeaturedBlogsProps) ?? undefined;
				if (data) {
					return <FeaturedBlogs {...data} />;
				} else {
					return <></>;
				}
			},
		},
		block: {
			// Customize different block types (e.g., normal, h1, h2)
			normal: ({ children }) => <p className={normalStyle}>{children}</p>,
			h1: ({ children }) => <h1 className={h1Style}>{children}</h1>,
			h2: ({ children }) => <h2 className={h2Style}>{children}</h2>,
			h3: ({ children }) => <h3 className={h3Style}>{children}</h3>,
			h4: ({ children }) => <h4 className={h4Style}>{children}</h4>,
			h5: ({ children }) => <h5 className={h5Style}>{children}</h5>,
			h6: ({ children }) => <h6 className={h6Style}>{children}</h6>,
			blockquote: ({ children }) => (
				<p className={[blockqouteStyle, style.blockquote].join(" ")}>
					{children}
				</p>
			),
		},
		marks: {
			// Customize text marks (e.g., bold, italic)
			strong: ({ children }) => (
				<strong className={strongStyle}>{children}</strong>
			),
			link: ({ children, value }) => (
				<a
					href={value.href}
					className={linkStyle}
					target="_blank"
					rel="noopener noreferrer"
				>
					{children}
				</a>
			),
			// Add more marks as needed
		},
		list: {
			// Customize lists
			bullet: ({ children }) => <ul className={bulletStyle}>{children}</ul>,
			number: ({ children }) => <ol className={numberedStyle}>{children}</ol>,
		},
		listItem: {
			// Customize list items
			bullet: ({ children }) => <li className={bulletStyle}>{children}</li>,
			number: ({ children }) => <li className={numberedStyle}>{children}</li>,
		},
	};

	return <PortableText value={value} components={overrideComponent} />;
};

export default PortableTextArea;
