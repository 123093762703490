import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Client from "../../sanityClient";
import { useWindowSize } from "../../modules/useWindowSize";

import { FetchBlogPayload, BlogData } from "./interfaces";

export const FetchBlogs = async () => {
	try {
		const res = await Client.fetch(FetchBlogPayload);
		return (res as BlogData[]) ?? undefined;
	} catch (err) {
		throw err;
	}
};

export const useBlogs = () => {
	const navigation = useNavigate();
	const { isMobileWidth } = useWindowSize();
	const { data, isLoading } = useQuery({
		queryKey: ["blogs"],
		queryFn: FetchBlogs,
	});

	const navigateTo = (id: string) => {
		console.log("TG>>> navigating to ", id);
		navigation(`/blog/${id}`);
	};

	return {
		blogs: data,
		isLoading,
		navigateTo,
		isMobileWidth,
	};
};
