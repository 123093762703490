import React from "react";
import { ClipLoader } from "react-spinners";

import * as mainUtils from "../../utils/main";
import styles from "./styles.module.css";

interface Props {
	title?: string;
	onClick?: () => void;
	containerStyles?: string;
	titleStyles?: string;
	isLoading?: boolean;
}

const Button: React.FC<Props> = ({
	title,
	onClick,
	containerStyles,
	titleStyles,
	isLoading = false,
}) => {
	return (
		<div onClick={onClick} className={`${styles.container} ${containerStyles}`}>
			{isLoading ? (
				<ClipLoader loading={isLoading} size={20} color="#171717" />
			) : (
				<h4 className={`${styles.title} ${titleStyles}`}>{title}</h4>
			)}
		</div>
	);
};

export default Button;
