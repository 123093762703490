import React from "react";
import style from "./style.module.css";

interface props {
	fieldLabel?: string;
	suffix?: string;
	icon?: string;
	onChange?: (val: string) => void;
}

const InputFields: React.FC<props> = ({
	fieldLabel,
	suffix,
	icon,
	onChange,
}) => {
	return (
		<div className={style.mainContainer}>
			<p className={style.fieldLabel}>{fieldLabel}</p>
			<div className={style.inputContainter}>
				{icon && <img src={icon} alt="icons" className={style.icon} />}
				<input
					className={icon ? style.inputFieldRight : style.inputFieldLeft}
					onChange={(event) => onChange && onChange(event.target.value)}
				/>
				{suffix && <h3 className={style.suffix}>{suffix}</h3>}
			</div>
		</div>
	);
};

export default InputFields;
