import React, { ReactComponentElement } from "react";
import { useEstimate } from "./hook";

import Field from "../text-field";
import Forms from "../forms";
import Button from "../button";
import LoadingView from "../loading-view";

import style from "./style.module.css";
import * as mainUtils from "../../utils/main";
import { Icons } from "../../utils/Icons";
import PortableTextArea from "../portable-text";

const EstimateView = () => {
	const {
		defineFloorArea,
		defineStorey,
		getEstimate,
		hasEstimate,
		onDiscussMore,
		contentData,
		data,
		isLoading,
	} = useEstimate();

	return isLoading ? (
		<LoadingView />
	) : (
		<Forms headerTitle={data?.pageTitle} subHeader={contentData?.subHeader}>
			<Field
				fieldLabel={contentData?.floorField}
				suffix=""
				icon={Icons.floorIcon}
				onChange={defineStorey}
			/>
			<Field
				fieldLabel={contentData?.areaField}
				suffix="SQM"
				icon={Icons.floorAreaIcon}
				onChange={defineFloorArea}
			/>
			<div className={style.totalContainer}>
				<h3 className={style.totalLabels}>{contentData?.totalCostLabel}</h3>
				<h3 className={style.totalLabels}>{getEstimate}</h3>
			</div>
			<PortableTextArea value={contentData?.info} normalStyle={style.notes} />
			{hasEstimate && (
				<Button title={contentData?.submitCTA} onClick={onDiscussMore} />
			)}
		</Forms>
	);
};

export default EstimateView;
