import { useQuery } from "@tanstack/react-query";
import { useHeaderFooterStore } from "./store";
import { fetchHeaderFooter } from "./hooks";
import { useEffect } from "react";

const useHeaderFooter = () => {
	const { setLoading, setData, setError, contentData } = useHeaderFooterStore();
	const { data, isLoading, isError } = useQuery({
		queryKey: ["header-footer"],
		queryFn: fetchHeaderFooter,
	});

	useEffect(() => {
		setLoading(isLoading);
		setData(data);
		setError(isError);
	}, [isLoading, setLoading, data, setData, setError, isError]);

	return {
		contentData,
		isLoading,
	};
};

export { useHeaderFooter, useHeaderFooterStore };
