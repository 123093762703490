import { useEffect, useMemo } from "react";
import Client from "../../sanityClient";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { formatDate } from "../../utils/date";

import { BlogDetailedPayload } from "./interfaces";
import { BlogData } from "../blogs/interfaces";

const fetchBlogDetailedContent = async (id?: string) => {
	try {
		const result = await Client.fetch(BlogDetailedPayload, { id });
		return result.length > 0 ? (result[0] as BlogData) : undefined;
	} catch (error) {
		throw error;
	}
};

export const useBlogDetail = () => {
	const { blogID } = useParams();
	const { data, isLoading } = useQuery({
		queryKey: ["blog-detail", blogID],
		queryFn: () => fetchBlogDetailedContent(blogID),
	});

	useEffect(() => {
		window.scroll(0, 0);
	}, [blogID, data]);

	const tagline = useMemo(() => {
		return data?.tagline;
	}, [data]);

	const header = useMemo(() => {
		return data?.header;
	}, [data]);

	const postedDate = useMemo(() => {
		if (!data?._createdAt) return "";

		const formattedDate = formatDate(data._createdAt);
		return `POSTED: ${formattedDate}`;
	}, [data]);

	const contents = useMemo(() => {
		return data?.contents ?? [];
	}, [data]);

	const headerImage = useMemo(() => {
		return data?.headerImage ?? undefined;
	}, [data]);

	return {
		tagline,
		header,
		postedDate,
		contents,
		headerImage,
		isLoading,
	};
};
