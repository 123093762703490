export const HomeContentPayload = `
  *[_id == "8d97c67e-a946-4fde-bb13-11226c52a7f0"] {
    headerImage {
    asset -> {
      _id,
      url 
    }
  },
    body,
  }
`;
