import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useProjects } from "../../stores/useProject";
import Client from "../../sanityClient";
import { useQuery } from "@tanstack/react-query";

import { EstimateContentPayload } from "./interfaces";
import { PageContentData } from "../../interfaces/pageContentSanityInterfaces";
import { SupportedFeaturedSchema } from "../../client/supportedComponentSchema";
import { InitialEstimatesProps } from "../../interfaces/InitialEstiamtes";

const fetchEstimateContent = async () => {
	try {
		const result = await Client.fetch(EstimateContentPayload);
		return result.length > 0 ? (result[0] as PageContentData) : undefined;
	} catch (err) {
		throw err;
	}
};

export const useEstimate = () => {
	const [floorArea, setFloorArea] = useState<number>();
	const [storey, setStoreys] = useState<number>();
	const [hasEstimate, setHasEstimate] = useState<boolean>(false);
	const { setEstimates } = useProjects();
	const navigate = useNavigate();
	let lowPrice: number = 30000;
	let midPrice: number = 33000;
	let highPrice: number = 35000;

	const { data, isLoading } = useQuery({
		queryKey: ["estimate-content"],
		queryFn: fetchEstimateContent,
	});

	const contentData = useMemo(() => {
		return data?.body?.content[0] as any as InitialEstimatesProps;
	}, [data]);

	const getEstimate = useMemo((): string => {
		if (storey && floorArea && storey > 0 && floorArea > 0) {
			var estimatedVal: number;

			if (storey <= 2) {
				estimatedVal = floorArea * lowPrice;
			} else if (storey <= 4) {
				estimatedVal = floorArea * midPrice;
			} else {
				estimatedVal = floorArea * highPrice;
			}

			// const newEstimate = convertToCurrency(estimatedVal);

			setHasEstimate(true);

			return estimatedVal.toLocaleString("en-PH", {
				style: "currency",
				currency: "PHP",
			});
		} else {
			setHasEstimate(false);
			return "₱ 0.00";
		}
	}, [floorArea, highPrice, lowPrice, midPrice, storey]);

	const convertToCurrency = (newValue: number): string => {
		return newValue.toLocaleString("en-PH", {
			style: "currency",
			currency: "PHP",
		});
	};

	const defineFloorArea = (value: string) => {
		// TODO: check if number if not return error
		setFloorArea(Number(value));
	};

	const defineStorey = (value: string) => {
		//TODO: check if number if not return error
		setStoreys(Number(value));
	};

	const onDiscussMore = () => {
		setEstimates(floorArea, storey);
		navigate("/contact/");
	};

	return {
		defineFloorArea,
		defineStorey,
		getEstimate,
		hasEstimate,
		onDiscussMore,
		contentData,
		data,
		isLoading,
	};
};
