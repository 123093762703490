import { pageSchema } from "./pageSchema";
import { basicSectionSchema } from "./basicSectionSchema";
import {
	headerFooterSchema,
	menuLinkSchema,
	socialMenuLinkSchema,
} from "./headerFooterSchema";
import { imageSchema, iconSchema, groupImages } from "./imageSchema";
import {
	featuredSectionBulletSchema,
	featuredSectionListSchema,
	featuredProjectsSchema,
	featuredBlogPostSchema,
} from "./featuredSectionSchema";
import { contactFormSectionSchema } from "./contactFormSectionSchema";
import { estimageFormSectionSchema } from "./estimageFormSectionSchema";
import { projectsSchema } from "./projectSchema";
import { blogSchema } from "./blogSchema";
import { ctaSchema } from "./ctaSchema";
import { teamSchema, employeeSchema } from "./teamSchema";

export const schemas = [
	pageSchema,
	basicSectionSchema,
	headerFooterSchema,
	imageSchema,
	iconSchema,
	menuLinkSchema,
	socialMenuLinkSchema,
	featuredSectionBulletSchema,
	featuredSectionListSchema,
	contactFormSectionSchema,
	estimageFormSectionSchema,
	projectsSchema,
	blogSchema,
	ctaSchema,
	teamSchema,
	employeeSchema,
	featuredProjectsSchema,
	groupImages,
	featuredBlogPostSchema,
];
