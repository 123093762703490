import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useHeaderFooter } from "../hooks/headerFooter";

import RootLayout from "./rootLayout";
import Home from "../pages/home";
import Projects from "../pages/projects";
import ComingSoon from "../pages/coming-soon";
import ContactUs from "../pages/contact-us";
import ProjecDetail from "../pages/project-detail";
import InitialEstimate from "../pages/initial-estimate";
import StudioRoute from "../pages/admin";
import BlogDetail from "../pages/blog-detail";
import Blogs from "../pages/blogs";
import AboutUs from "../pages/about-us";
import LoadingView from "../components/loading-view";
import ErrorPage from "../pages/error-page";

import style from "./style.module.css";

const router = createBrowserRouter([
	{
		path: "/",
		element: <RootLayout />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "projects/",
				element: <Projects />,
			},
			{
				path: "about/",
				element: <AboutUs />,
			},
			{
				path: "contact/",
				element: <ContactUs />,
			},
			{
				path: "project/:projectID",
				element: <ProjecDetail />,
			},
			{
				path: "estimate/",
				element: <InitialEstimate />,
			},
			{
				path: "admin/",
				element: <StudioRoute />,
			},
			{
				path: "blog/:blogID",
				element: <BlogDetail />,
			},
			{
				path: "blogs/",
				element: <Blogs />,
			},
			// Catch-all route
			{
				path: "*",
				element: <ErrorPage />,
			},
		],
	},
]);

const Navigation = () => {
	const { isLoading } = useHeaderFooter();

	return isLoading ? (
		<div className={style.mainContainerLoadingView}>
			<LoadingView />{" "}
		</div>
	) : (
		<RouterProvider router={router} />
	);
};

export default Navigation;
