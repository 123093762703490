import { Image } from "../../interfaces/commonSanityInterfaces";

export const FeaturedBlogQueryPayload = `*[_type == 'blogSchema' && _id in [$id1, $id2, $id3]] {
   _id,
  _createdAt,
  header,
  headerImage {
    asset -> {
      _id,
      url 
    }
  },
}`;

export interface FeaturedBlogData {
	_id: string;
	_createdAt: Date;
	header: string;
	headerImage: Image;
}
