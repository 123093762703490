import { useNavigate } from "react-router-dom";
import { PopupButton } from "react-calendly";
import { useWindowSize } from "../../modules/useWindowSize";

import styles from "./style.module.css";

interface Props {
	message: string;
	bookButtonLabel: string;
	estimateButtonLabel: string;
}

const CTA: React.FC<Props> = ({
	message,
	bookButtonLabel,
	estimateButtonLabel,
}) => {
	const navigate = useNavigate();
	const { isMobileWidth } = useWindowSize();

	return (
		<div
			className={
				isMobileWidth ? styles.ctaContainerMobile : styles.ctaContainer
			}
		>
			<div className={styles.ctaMessageContainer}>
				<h4
					className={
						isMobileWidth ? styles.ctaMessageMobile : styles.ctaMessage
					}
				>
					{message}
				</h4>
			</div>
			<div
				className={
					isMobileWidth
						? styles.ctaButtonContainerMobile
						: styles.ctaButtonContainer
				}
			>
				<PopupButton
					url="https://calendly.com/gd-studio/15-minute-project-assessment?fbclid=IwY2xjawFvfvhleHRuA2FlbQIxMAABHfWvfmM7Z-qHv2gR3-N8hiQrLp6cef4cgoNxYT9LLONq6CNFuuYqwWMDFA_aem_QICHrKMHlrn8FWy_HKTrsg&month=2024-10"
					rootElement={document.getElementById("root")!}
					text={bookButtonLabel}
					className={styles.calendyButton}
				/>
				<h5
					className={styles.estimateButton}
					onClick={() => navigate("/estimate/")}
				>
					{estimateButtonLabel}
				</h5>
			</div>
		</div>
	);
};

export default CTA;
