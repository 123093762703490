export const blogSchema = {
	name: "blogSchema",
	title: "Blogs",
	type: "document",
	fields: [
		{
			name: "header",
			title: "Header Title",
			type: "string",
		},
		{
			name: "tagline",
			title: "Tagline",
			type: "string",
		},
		{
			name: "headerImage",
			title: "Header Image",
			type: "imageSchema",
		},
		{
			name: "contents",
			title: "Contents",
			type: "array",
			of: [
				{
					name: "paragraph",
					title: "Paragraph",
					type: "block",
				},
				{
					name: "imagesArray",
					title: "Group Images",
					type: "groupImages",
				},
				{
					name: "projects",
					title: "Projects",
					type: "reference",
					to: [{ type: "projectSchema" }],
				},
				{
					name: "cta",
					title: "CTA",
					type: "ctaSchema",
				},
				{
					name: "featuredBullet",
					title: "Featured Bullet",
					type: "featuredSectionBulletSchema",
				},
				{
					name: "featuredList",
					title: "Featured List",
					type: "featuredSectionListSchema",
				},
				{
					name: "featuredProjects",
					title: "Featured Projects",
					type: "featuredProjectsSchema",
				},
				{
					name: "featuredBlogs",
					title: "Featured Blogs",
					type: "featuredBlogPostSchema",
				},
			],
		},
	],
	preview: {
		select: {
			title: "header", // Update to reflect the correct field
		},
	},
};
