export const headerFooterSchema = {
	name: "headerFooter",
	title: "Header & Footer Content",
	type: "document",
	fields: [
		{
			name: "headerFooterTitle",
			title: "Title",
			type: "string",
		},
		{
			name: "headerMenus",
			title: "Header Menus",
			type: "array",
			of: [{ type: "menuLinkSchema" }],
		},
		{
			name: "headerSiteLogo",
			title: "Site Logo",
			description: "This logo will be use for all pages",
			type: "imageSchema",
		},
		{
			name: "footerSiteLogo",
			title: "Footer Site Logo",
			description: "This logo will be use for all pages",
			type: "imageSchema",
		},
		{
			name: "freeEstimateCTA",
			title: "Free Estimate Title",
			description: "CTA(Call To Action) title label.",
			type: "string",
		},
		{
			name: "facebookLink",
			title: "Facebook Link",
			type: "socialMenuLinkSchema",
		},
		{
			name: "instagramLink",
			title: "Instagram Link",
			type: "socialMenuLinkSchema",
		},
		{
			name: "tiktokLink",
			title: "Tiktok Link",
			type: "socialMenuLinkSchema",
		},
		{
			name: "footerDescription",
			title: "Footer Description",
			description:
				"This will be shown in the footer, add links, address or trademark here",
			type: "array",
			of: [{ type: "block" }],
		},
	],
};

export const menuLinkSchema = {
	name: "menuLinkSchema",
	title: "Menu",
	type: "object",
	fields: [
		{
			name: "menuName",
			title: "Name",
			type: "string",
		},
		{
			name: "menuURL",
			type: "slug",
			options: {
				source: "menuName",
			},
		},
		{
			name: "isEnable",
			title: "Show/Hide",
			type: "boolean",
		},
	],
};

export const socialMenuLinkSchema = {
	name: "socialMenuLinkSchema",
	title: "Social Menu Link",
	type: "object",
	fields: [
		{
			name: "menuName",
			title: "Name",
			type: "string",
		},
		{
			name: "socialURL",
			title: "Link",
			type: "url",
		},
		{
			name: "socialIcon",
			type: "iconSchema",
		},
		{
			name: "isEnable",
			title: "Show/Hide",
			type: "boolean",
		},
	],
};
