export const projectsSchema = {
	name: "projectSchema",
	title: "Projects",
	type: "document",
	fields: [
		{
			name: "headerTitle",
			type: "basicSectionSchema",
		},
		{
			name: "backgroundImage",
			title: "Featured Image",
			description:
				"This will be displayed in the background image and as a header image",
			type: "imageSchema",
		},
		{
			name: "shortDescription",
			title: "Short Description",
			description: "This will be use on showing project as a featured",
			type: "array",
			of: [{ type: "block" }],
		},
		{
			name: "contents",
			title: "Contents",
			type: "array",
			of: [
				{
					name: "paragraph",
					title: "Paragraph",
					type: "block",
				},
				{
					name: "images",
					title: "Images",
					type: "imageSchema",
				},
				{
					name: "cta",
					title: "CTA",
					type: "ctaSchema",
				},
				{
					name: "imagesArray",
					title: "Group Images",
					type: "groupImages",
				},
			],
		},
	],
	preview: {
		select: {
			title: "headerTitle.headerTitle", // Update to reflect the correct field
		},
	},
};
