export const imageSchema = {
	name: "imageSchema",
	title: "Image",
	type: "image",
	options: {
		hotspot: true,
	},
	validation: (rule: any) => rule.required(),
	fields: [
		{
			name: "altText",
			title: "Alt Text",
			description:
				"Include concise and relevant alt text that describes the image and incorporates primary keywords. This helps search engines understand the content and is crucial for accessibility.",
			type: "string",
			validation: (rule: any) => rule.required(),
		},
		{
			name: "captions",
			title: "Captions",
			description:
				"Add image captions where appropriate. They can provide more context and are often read by users, improving engagement.",
			type: "string",
		},
		{
			name: "link",
			title: "Link",
			description: "Add custom link",
			type: "url",
		},
	],
};

export const iconSchema = {
	name: "iconSchema",
	title: "Icon",
	type: "image",
	fields: [
		{
			name: "altText",
			title: "Alt Text",
			description:
				"Include concise and relevant alt text that describes the image and incorporates primary keywords. This helps search engines understand the content and is crucial for accessibility.",
			type: "string",
			validation: (rule: any) => rule.required(),
		},
	],
};

export const groupImages = {
	name: "groupImages",
	title: "Group Images",
	type: "object",
	fields: [
		{
			name: "images",
			title: "Select Group Of Photos",
			type: "array",
			of: [
				{
					type: "imageSchema",
				},
			],
		},
	],
};
