import React from "react";
import { defineConfig, Studio } from "sanity";
import { structureTool } from "sanity/structure";
import { visionTool } from "@sanity/vision";
import { sanityProjectID, sanityDataset } from "../../sanityClient";

import styles from "./styles.module.css";
import { schemas } from "../../models/schemas";

export const config = defineConfig({
	name: "GD-Studio",
	projectId: sanityProjectID,
	dataset: sanityDataset,
	basePath: "/admin",
	plugins: [structureTool(), visionTool()],
	schema: {
		types: schemas,
	},
	unstable_noUpdate: true, // Disables auto updates
});

const StudioRoute = () => {
	return (
		<div className={styles.container}>
			<Studio config={config} />;
		</div>
	);
};

export default StudioRoute;
