import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useProjectListView = () => {
	const navigate = useNavigate();
	const [isHovered, setHoverState] = useState<boolean>(false);
	const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

	useEffect(() => {
		const resizeWindowWidth = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", resizeWindowWidth);

		return () => {
			window.removeEventListener("resize", resizeWindowWidth);
		};
	}, []);

	const setHover = (state: boolean) => {
		setHoverState(windowWidth > 800 && state);
	};

	const navigateTo = (id: string) => {
		navigate(`/project/${id}`);
	};

	return { navigateTo, isHovered, setHover };
};
