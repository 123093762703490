import { create } from "zustand";

import { HeaderFooterContentData, HeaderFooterStoreConfig } from "./interfaces";

export const useHeaderFooterStore = create<HeaderFooterStoreConfig>(
	(set, get) => ({
		isLoading: true,
		isError: false,
		contentData: undefined,
		setLoading: (isLoading: boolean) => {
			set({ isLoading });
		},
		setData: (contentData: HeaderFooterContentData | undefined) => {
			set({ contentData });
		},
		setError: (isError: boolean) => {
			set({ isError });
		},
	})
);
