import Lottie from "lottie-react";
import GDLogoAnimation from "../../assets/gdlogo-animated.json";

import style from "./style.module.css";

const LoadingView = () => {
	return (
		<div className={style.mainContainer}>
			<div className={style.logoContainer}>
				<Lottie animationData={GDLogoAnimation} loop />
			</div>
			<h3 className={style.title}>sketching...</h3>
		</div>
	);
};

export default LoadingView;
