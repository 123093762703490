import sanityClient from "../../sanityClient";
import { headerFooterPayload, HeaderFooterContentData } from "./interfaces";

export const fetchHeaderFooter = async () => {
	try {
		const result = await sanityClient.fetch(headerFooterPayload);
		return result.length > 0
			? (result[0] as HeaderFooterContentData)
			: undefined;
	} catch (err) {
		throw err;
	}
};
