import hero from "../assets/gdLogoSoon.png";
import facebookIcon from "../assets/facebookIcon.svg";
import instagramIcon from "../assets/instagramIcon.svg";
import tiktokIcon from "../assets/tiktokIcon.svg";
import hamburgerButtonIcon from "../assets/hamburgerButton.svg";
import closeButtonIcon from "../assets/closeButton.svg";
import floorIcon from "../assets/floorsIcon.svg";
import floorAreaIcon from "../assets/floorAreaIcon.svg";

export const Icons = {
	hero,
	facebookIcon,
	instagramIcon,
	tiktokIcon,
	hamburgerButtonIcon,
	closeButtonIcon,
	floorIcon,
	floorAreaIcon,
};

export const Images = {
	hero: "https://res.cloudinary.com/dfthwktaz/image/upload/v1673085491/gd-studio/home/home-cover_c7s8b2.png",
};

export const ProjectImages = {
	projectCover:
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1673087013/gd-studio/home/showroomCover_iaducz.jpg",
	showRooms: [
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1673087008/gd-studio/home/showroom-2_jgrceg.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1673087013/gd-studio/home/showroom-3_l884kz.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1673087008/gd-studio/home/showroom-1_laz4ib.jpg",
		"https://res.cloudinary.com/dfthwktaz/image/upload/v1673087021/gd-studio/home/showroom-4_bo5fnz.jpg",
	],
};
