import { useAboutUs } from "./hooks";
import { useWindowSize } from "../../modules/useWindowSize";

import ContentImage from "../../components/content-image";
import PortableTextArea from "../../components/portable-text";
import LoadingView from "../../components/loading-view";

import style from "./style.module.css";
import { SupportedFeaturedSchema } from "../../client/supportedComponentSchema";

const AboutUs = () => {
	const { headerImage, headerTitle, tagline, body, isLoading } = useAboutUs();
	const { isMobileWidth } = useWindowSize();

	return isLoading ? (
		<LoadingView />
	) : (
		<div className={style.mainContainers}>
			<div className={style.welcomeContainer}>
				{headerImage && (
					<ContentImage
						id={headerImage.asset._id ?? ""}
						alt={headerImage.altText}
						style={style.homeCover}
					/>
				)}
				<div className={style.overlay}>
					<h3 className={style.tagline}>{tagline}</h3>
					<h1 className={style.headerTitle}>{headerTitle}</h1>
				</div>
			</div>
			<div className={style.body}>
				<PortableTextArea
					value={body}
					normalStyle={style.bodyText}
					bulletStyle={style.bodyText}
					numberedStyle={style.bodyText}
					h1Style={style.bodyText}
					h2Style={style.bodyText}
					h3Style={style.bodyText}
					h4Style={style.bodyText}
					h5Style={style.bodyText}
					h6Style={style.bodyText}
					blockqouteStyle={style.bodyText}
				/>
			</div>
		</div>
	);
};

export default AboutUs;
