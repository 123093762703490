import {
	Asset,
	Child,
	Image,
	Contents,
} from "../../interfaces/commonSanityInterfaces";

export const FetchProjectsPayload = `
*[_type == 'projectSchema'] {
  _id,
  headerTitle {
    headerTitle,
    subHeaderTitle,
    content[]
  },
  backgroundImage {
    asset -> {
      _id,
      url 
    }
  },
  contents[]
}
`;

export interface ProjectData {
	_id: string;
	headerTitle: HeaderTitle;
	backgroundImage: Image;
	contents: Contents[];
	shortDescription?: Contents[];
}

export interface HeaderTitle {
	headerTitle: string;
	subHeaderTitle: string;
	content: HeaderTitleContent[];
}

export interface HeaderTitleContent {
	children: Child[];
	_type: string;
	style: string;
	_key: string;
	markDefs: any[];
}
