import { formatDate } from "../../utils/date";

import ContentImage from "../content-image";

import { BlogData } from "../../pages/blogs/interfaces";
import style from "./style.module.css";

interface Props extends BlogData {
	onClick: (blogID: string) => void;
}

const BlogListViewHorizontal: React.FC<Props> = ({
	headerImage,
	onClick,
	_id,
	header,
	_createdAt,
}) => {
	const imageBackgroundStyle = {
		backgroundImage: `url(${headerImage.asset.url})`,
	};

	return (
		<div className={style.container} onClick={() => onClick(_id)}>
			{headerImage && (
				<ContentImage
					id={headerImage.asset._id ?? ""}
					alt={headerImage.altText}
					style={style.headerImage}
				/>
			)}
			<div className={style.blogTitleContainer}>
				<h3 className={style.header}>{header}</h3>
				<h4 className={style.date}>{formatDate(_createdAt)}</h4>
			</div>
		</div>
	);
};

export default BlogListViewHorizontal;
