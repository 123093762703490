import Client from "../../sanityClient";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../modules/useWindowSize";

import { FeaturedBlogs } from "../../interfaces/featuredBlogs";
import { FeaturedBlogQueryPayload, FeaturedBlogData } from "./interfaces";

const fetchAllFeaturedProjects = async (ids: string[]) => {
	try {
		const result = await Client.fetch(FeaturedBlogQueryPayload, {
			id1: ids[0],
			id2: ids[1],
			id3: ids[2],
		});
		return (result as FeaturedBlogData[]) ?? undefined;
	} catch (error) {
		throw error;
	}
};

export const useFeaturedBlogs = (blogs: FeaturedBlogs) => {
	const navigate = useNavigate();
	const { isMobileWidth } = useWindowSize();
	const blogIDs: string[] = blogs.blogs.map((blog) => blog._ref);
	const { data, isLoading } = useQuery({
		queryKey: ["featured-blogs", { ids: blogIDs }],
		queryFn: () => fetchAllFeaturedProjects(blogIDs),
	});

	const navigateTo = (id: string) => {
		navigate(`/blog/${id}`);
	};

	return {
		data,
		isLoading,
		navigateTo,
		isMobileWidth,
	};
};
