import { useProject } from "./hooks";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../modules/useWindowSize";

import ContentImage from "../content-image";
import PortableTextArea from "../portable-text";

import styles from "./style.module.css";
import InfoBox from "../info-box";

interface Props {
	id: string;
}

const ProjectAttached: React.FC<Props> = ({ id }) => {
	const navigate = useNavigate();
	const { data, isLoading } = useProject(id);
	const { isMobileWidth } = useWindowSize();

	return (
		<div
			className={
				isMobileWidth ? styles.mainContainerMobile : styles.mainContainer
			}
			onClick={() => navigate(`/project/${id}`)}
		>
			{data?.backgroundImage && (
				<div
					className={
						isMobileWidth ? styles.imageContainerMobile : styles.imageContainer
					}
				>
					<ContentImage
						id={data.backgroundImage.asset._id ?? ""}
						alt={data.backgroundImage.altText}
						crop={data.backgroundImage.crop}
						hotSpot={data.backgroundImage.hotspot}
						height={500}
						mode="cover"
						style={isMobileWidth ? styles.imageMobile : styles.image}
					/>
				</div>
			)}
			<div
				className={
					isMobileWidth
						? styles.headerTitleContainerMobile
						: styles.headerTitleContainer
				}
			>
				<h4 className={isMobileWidth ? styles.tagLineMobile : styles.tagLine}>
					{data?.headerTitle.subHeaderTitle}
				</h4>
				<h2
					className={
						isMobileWidth ? styles.headerTitleMobile : styles.headerTitle
					}
				>
					{data?.headerTitle.headerTitle}
				</h2>
				<PortableTextArea
					value={data?.shortDescription}
					normalStyle={
						isMobileWidth
							? styles.shortDescriptionMobile
							: styles.shortDescription
					}
					h1Style={
						isMobileWidth
							? styles.shortDescriptionMobile
							: styles.shortDescription
					}
					h2Style={
						isMobileWidth
							? styles.shortDescriptionMobile
							: styles.shortDescription
					}
					h3Style={
						isMobileWidth
							? styles.shortDescriptionMobile
							: styles.shortDescription
					}
					h4Style={
						isMobileWidth
							? styles.shortDescriptionMobile
							: styles.shortDescription
					}
					h5Style={
						isMobileWidth
							? styles.shortDescriptionMobile
							: styles.shortDescription
					}
					h6Style={
						isMobileWidth
							? styles.shortDescriptionMobile
							: styles.shortDescription
					}
					linkStyle={
						isMobileWidth
							? styles.shortDescriptionMobile
							: styles.shortDescription
					}
					bulletStyle={
						isMobileWidth
							? styles.shortDescriptionMobile
							: styles.shortDescription
					}
					numberedStyle={
						isMobileWidth
							? styles.shortDescriptionMobile
							: styles.shortDescription
					}
					blockqouteStyle={
						isMobileWidth
							? styles.shortDescriptionMobile
							: styles.shortDescription
					}
				/>
			</div>
		</div>
	);
};

export default ProjectAttached;
