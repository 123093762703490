export const ctaSchema = {
	name: "ctaSchema",
	title: "CTA",
	type: "object",
	fields: [
		{
			name: "ctaMessage",
			title: "Message",
			type: "string",
		},
		{
			name: "ctaBookButtonLabel",
			title: "Book Now Label",
			type: "string",
		},
		{
			name: "ctaEstimateButtonLabel",
			title: "Estimate Label",
			type: "string",
		},
	],
	initialValue: {
		ctaMessage:
			"LET US GUIDE YOU TO TURN YOUR VISION OF THE PERFECT HOME INTO A STUNNING REALITY",
		ctaBookButtonLabel: "SCHEDULE A MEETING WITH US",
		ctaEstimateButtonLabel: "OR GET A FREE ESTIMATE",
	},
};
