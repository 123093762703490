// hooks
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Client from "../../sanityClient";
import { ProjectDetailedPayload } from "./interfaces";
import { ProjectData } from "../projects/interfaces";

const fetchProjectDetailContent = async (id?: string) => {
	try {
		const result = await Client.fetch(ProjectDetailedPayload, { id });
		return result.length > 0 ? (result[0] as ProjectData) : undefined;
	} catch (error) {
		throw error;
	}
};

export const useProjectDetail = () => {
	const { projectID } = useParams();
	const { data, isLoading } = useQuery({
		queryKey: ["project-detail", projectID],
		queryFn: () => fetchProjectDetailContent(projectID),
	});

	useEffect(() => {
		window.scroll(0, 0);
	}, [projectID, data]);

	const name = useMemo(() => {
		return data?.headerTitle?.headerTitle ?? undefined;
	}, [data]);

	const headerBody = useMemo(() => {
		return data?.headerTitle?.content ?? undefined;
	}, [data]);

	const contents = useMemo(() => {
		return data?.contents ?? undefined;
	}, [data]);

	const headerImage = useMemo(() => {
		return data?.backgroundImage ?? undefined;
	}, [data]);

	return {
		name,
		headerBody,
		contents,
		headerImage,
		isLoading,
	};
};
