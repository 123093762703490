import Client from "../../sanityClient";
import { useQuery } from "@tanstack/react-query";

import { FeaturedProjectQueryPayload, FeaturedProjectData } from "./interfaces";
import { FeaturedProjects } from "../../interfaces/featuredProjects";

const fetchAllFeaturedProjects = async (ids: string[]) => {
	try {
		const result = await Client.fetch(FeaturedProjectQueryPayload, {
			id1: ids[0],
			id2: ids[1],
			id3: ids[2],
		});
		return (result as FeaturedProjectData[]) ?? undefined;
	} catch (error) {
		throw error;
	}
};

export const useFeaturedProjects = (project: FeaturedProjects) => {
	const projectIDs: string[] = project.projects.map((item) => item._ref);
	const { data, isLoading } = useQuery({
		queryKey: [
			"featured-projects",
			{ ids: project.projects.map((item) => item._ref) },
		],
		queryFn: () => fetchAllFeaturedProjects(projectIDs),
	});

	return {
		data,
		isLoading,
	};
};
