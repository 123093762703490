import { Asset, Child, MarkDef } from "../../interfaces/commonSanityInterfaces";

export interface HeaderFooterStoreConfig {
	isLoading: boolean;
	isError: boolean;
	contentData: HeaderFooterContentData | undefined;
	setLoading: (isLoading: boolean) => void;
	setData: (contentData: HeaderFooterContentData | undefined) => void;
	setError: (isError: boolean) => void;
}

export const headerFooterPayload = `
  *[_id == "59ace009-4e89-4cac-9b23-41a2c13c570c"]{			
    headerMenus[]{
        menuName,
        "menuURL": menuURL.current
    },
    headerSiteLogo,
		footerSiteLogo,
    facebookLink {
      socialURL,
      isEnable,
      socialIcon
    },
    instagramLink {
      socialURL,
      isEnable,
      socialIcon
    },
    tiktokLink {
      socialURL,
      isEnable,
      socialIcon
    },
    footerDescription,
    freeEstimateCTA,
  }
`;

export interface HeaderFooterContentData {
	instagramLink: Link;
	tiktokLink: Link;
	footerDescription: FooterDescription[];
	freeEstimateCTA: string;
	headerMenus: HeaderMenu[];
	headerSiteLogo: HeaderSiteLogo;
	footerSiteLogo: HeaderSiteLogo;
	facebookLink: Link;
}

export interface Link {
	socialURL: string;
	isEnable: boolean;
	socialIcon: HeaderSiteLogo;
}

export interface HeaderSiteLogo {
	altText: string;
	_type: string;
	asset: Asset;
	captions?: string;
}

export interface FooterDescription {
	children: Child[];
	_type: string;
	style: string;
	_key: string;
	markDefs?: MarkDef[];
}

export interface HeaderMenu {
	menuName: string;
	menuURL: string;
}
