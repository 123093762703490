import React from "react";
import { useContactUs } from "./hook";

import Forms from "../../components/forms";
import InputFields from "../../components/text-field";
import TextView from "../../components/text-view";
import Button from "../../components/button";
import LoadingView from "../../components/loading-view";
import ContentImage from "../../components/content-image";

import styles from "./style.module.css";

const ContactUs = () => {
	const store = useContactUs();
	// subHeader="Your message has been successfully sent to us. We will get back to you soon."

	if (store.isLoading) {
		return <LoadingView />;
	}

	return (
		<div>
			{store.data?.headerImage && (
				<ContentImage
					id={store.data.headerImage.asset._ref}
					alt={store.data.headerImage.altText}
					style={styles.imageHeader}
				/>
			)}
			{store.isMessageSent ? (
				<Forms
					headerTitle={store.contentData?.successMessageTitle}
					subHeader={store.contentData?.successMessage}
				>
					<Button
						title={store.contentData?.backButtonLabel}
						containerStyles={styles.button}
						onClick={store.onHome}
					/>
				</Forms>
			) : (
				<Forms headerTitle={store.headerTitle} subHeader={store.subHeader}>
					<InputFields
						fieldLabel={store.contentData?.nameField}
						onChange={(value) => store.setFullName(value)}
					/>
					<InputFields
						fieldLabel={store.contentData?.emailField}
						onChange={(value) => store.setEmail(value)}
					/>
					<InputFields
						fieldLabel={store.contentData?.phoneField}
						onChange={(value) => store.setPhoneNumber(value)}
					/>
					<TextView
						label={store.contentData?.messageField}
						onValueChange={(value) => store.setMessage(value)}
					/>
					<Button
						title={store.contentData?.submitCTA}
						containerStyles={styles.button}
						onClick={store.onSubmit}
						isLoading={store.isLoading}
					/>
				</Forms>
			)}
		</div>
	);
};

export default ContactUs;
