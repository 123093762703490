import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import Client from "../../sanityClient";

import { HomeContentPayload } from "./interfaces";
import { PageContentData } from "../../interfaces/pageContentSanityInterfaces";

const fetchHomeContent = async () => {
	try {
		const result = await Client.fetch(HomeContentPayload);
		return result.length > 0 ? (result[0] as PageContentData) : undefined;
	} catch (err) {
		throw err;
	}
};

export const useHome = () => {
	const { data: contentData, isLoading } = useQuery({
		queryKey: ["home-content-data"],
		queryFn: fetchHomeContent,
	});

	const headerImage = useMemo(() => {
		return contentData && contentData.headerImage;
	}, [contentData]);

	const headerTitle = useMemo(() => {
		return contentData?.body?.headerTitle;
	}, [contentData]);

	const body = useMemo(() => {
		return contentData?.body?.content;
	}, [contentData]);

	return {
		headerImage,
		headerTitle,
		body,
		isLoading,
	};
};
