import { SanityImage } from "sanity-image";
import { sanityProjectID, sanityDataset } from "../../sanityClient";
import { Crop, Hotspot } from "../../interfaces/commonSanityInterfaces";

export interface Props {
	id: string;
	alt: string;
	style?: string;
	hotSpot?: Hotspot;
	crop?: Crop;
	mode?: "cover" | "contain" | undefined;
	width?: number;
	height?: number;
	onClick?: () => void;
}

const ContentImage: React.FC<Props> = ({
	id,
	alt,
	style,
	hotSpot,
	crop,
	mode,
	width,
	height,
	onClick,
}) => {
	return (
		<SanityImage
			id={id}
			alt={alt}
			projectId={sanityProjectID}
			dataset={sanityDataset}
			className={style}
			hotspot={hotSpot}
			crop={crop}
			mode={mode}
			width={width}
			height={height}
			onClick={onClick}
		/>
	);
};

export default ContentImage;
