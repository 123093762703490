import { Image } from "../../interfaces/commonSanityInterfaces";

export const FeaturedProjectQueryPayload = `*[_type == 'projectSchema' && _id in [$id1, $id2, $id3]] {
  _id,
  headerTitle {
    headerTitle,
    subHeaderTitle,
  },
  backgroundImage {
    asset -> {
      _id,
      url
    }
  },
}`;

export interface FeaturedProjectData {
	_id: string;
	headerTitle: HeaderTitle;
	backgroundImage: Image;
}

export interface HeaderTitle {
	headerTitle: string;
	subHeaderTitle: string;
}
