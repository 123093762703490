import { useHeaderFooterStore } from "../../hooks/headerFooter";
import { useNavigate } from "react-router-dom";

import ContentImage from "../../components/content-image";
import Button from "../../components/button";

import style from "./style.module.css";

const ErrorPage = () => {
	const { contentData } = useHeaderFooterStore();
	const navigate = useNavigate();

	return (
		<div className={style.mainContainer}>
			{contentData?.headerSiteLogo && (
				<ContentImage
					id={contentData?.headerSiteLogo.asset._ref ?? ""}
					alt="GD-Logo"
					style={style.headerLogo}
				/>
			)}
			<div className={style.messageContainer}>
				<h3 className={style.headerTitle}>Oops! Something went wrong</h3>
				<Button title="Go Back to home" onClick={() => navigate("/")} />
			</div>
		</div>
	);
};

export default ErrorPage;
