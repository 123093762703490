import { useFeaturedBlogs } from "./hooks";

import PortableTextArea from "../portable-text";
import BlogListView from "../blog-list-view";
import BlogListViewHorizontal from "../blog-list-view-horizontal";

import style from "./style.module.css";
import { FeaturedBlogs as Props } from "../../interfaces/featuredBlogs";

const FeaturedBlogs: React.FC<Props> = (blogsProps: Props) => {
	const {
		data: blogs,
		navigateTo,
		isMobileWidth,
	} = useFeaturedBlogs(blogsProps);

	return (
		<div className={style.rootContainer}>
			<h2 className={style.header}>{blogsProps.header}</h2>
			<PortableTextArea
				value={blogsProps.description}
				normalStyle={style.description}
				bulletStyle={style.description}
				numberedStyle={style.description}
				h1Style={style.description}
				h2Style={style.description}
				h3Style={style.description}
				h4Style={style.description}
				h5Style={style.description}
				h6Style={style.description}
				blockqouteStyle={style.bodyText}
			/>
			<div className={style.mainContainer}>
				{isMobileWidth ? (
					<div className={style.listView}>
						{blogs &&
							blogs.map((blog) => (
								<BlogListViewHorizontal
									{...blog}
									onClick={(blogID) => navigateTo(blogID)}
								/>
							))}
					</div>
				) : (
					<div className={style.gridView}>
						{blogs &&
							blogs.map((blog) => (
								<BlogListView
									onClick={(blogID: string) => navigateTo(blogID)}
									{...blog}
								/>
							))}
					</div>
				)}
			</div>
		</div>
	);
};

export default FeaturedBlogs;
