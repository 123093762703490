export const estimageFormSectionSchema = {
	name: "estimageFormSectionSchema",
	title: "Estimate Section",
	type: "object",
	fields: [
		{
			name: "header",
			title: "Header Title",
			type: "string",
		},
		{
			name: "subHeader",
			title: "Sub Header",
			type: "array",
			of: [{ type: "block" }],
		},
		{
			name: "floorField",
			title: "Floor Field Question Label",
			type: "string",
		},
		{
			name: "areaField",
			title: "Area Field Question Label",
			type: "string",
		},
		{
			name: "totalCostLabel",
			title: "Total Cost Label",
			type: "string",
		},
		{
			name: "info",
			title: "Disclaimer Info",
			type: "array",
			of: [{ type: "block" }],
		},
		{
			name: "submitCTA",
			title: "Submit CTA Label",
			type: "string",
		},
		{
			name: "backButtonLabel",
			title: "Back Button Label",
			type: "string",
		},
		{
			name: "successMessage",
			type: "array",
			of: [{ type: "block" }],
		},
		{
			name: "successMessageTitle",
			title: "Success Message Title",
			type: "string",
		},
	],
};
