import React from "react";
import { useWindowSize } from "../../modules/useWindowSize";

import ContentImage from "../content-image";
import PortableTextArea from "../portable-text";

import styles from "./style.module.css";
import { Content, Entry } from "../../interfaces/pageContentSanityInterfaces";
import { FeaturedBulletList } from "../../interfaces/featuredBulletList";

const EntryContainer: React.FC<Entry> = (entry) => {
	return (
		<div className={styles.entryContainer}>
			{entry.bulletIcon && (
				<ContentImage
					id={entry.bulletIcon?.asset._ref}
					alt={entry.bulletIcon?.altText}
					style={styles.icon}
				/>
			)}
			<PortableTextArea
				value={entry.entry}
				normalStyle={styles.bulletedText}
				h1Style={styles.bulletedText}
				h2Style={styles.bulletedText}
				h3Style={styles.bulletedText}
				h4Style={styles.bulletedText}
				h5Style={styles.bulletedText}
				h6Style={styles.bulletedText}
				linkStyle={styles.bulletedText}
				bulletStyle={styles.bulletedText}
				numberedStyle={styles.bulletedText}
				blockqouteStyle={styles.bulletedText}
			/>
		</div>
	);
};

const FeatureSectionBullet: React.FC<Content | FeaturedBulletList> = ({
	tagline,
	header,
	image,
	content,
	entries,
}) => {
	const { isMobileWidth, windowSize } = useWindowSize();

	return (
		<div className={styles.mainContainer}>
			<div className={styles.textContainer}>
				{image && isMobileWidth && (
					<ContentImage
						id={image.asset._ref}
						alt={image.altText}
						crop={image.crop}
						hotSpot={image.hotspot}
						style={styles.image}
						mode="cover"
					/>
				)}
				<h4 className={styles.tagline}>{tagline}</h4>
				<h2 className={styles.header}>{header}</h2>
				<PortableTextArea
					value={content}
					normalStyle={styles.bulletedText}
					h1Style={styles.textColor}
					h2Style={styles.textColor}
					h3Style={styles.textColor}
					h4Style={styles.textColor}
					h5Style={styles.textColor}
					h6Style={styles.textColor}
					linkStyle={styles.textColor}
					bulletStyle={styles.textColor}
					numberedStyle={styles.textColor}
					blockqouteStyle={styles.textColor}
				/>
				<div className={styles.entriesContainer}>
					{entries?.map((entry) => <EntryContainer {...entry} />)}
				</div>
			</div>
			{!isMobileWidth && (
				<div className={styles.imageContainer}>
					{image && (
						<ContentImage
							id={image.asset._ref}
							alt={image.altText}
							crop={image.crop}
							hotSpot={image.hotspot}
							style={styles.image}
							mode="cover"
							width={windowSize.width * 0.3}
							height={windowSize.height * 0.5}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default FeatureSectionBullet;
