import EmployeeView from "../employee-view";
import PortableTextArea from "../portable-text";

import style from "./style.module.css";
import { InfoElement } from "../../interfaces/pageContentSanityInterfaces";
import { Employee } from "../../interfaces/pageContentSanityInterfaces";

interface Props {
	employees: Employee[];
	headerTitle: string;
	subHeader: InfoElement[];
}

const EmployeeSection: React.FC<Props> = ({
	employees,
	headerTitle,
	subHeader,
}) => {
	return (
		<div className={style.mainContainer}>
			<h2 className={style.headerTitle}>{headerTitle}</h2>
			<PortableTextArea
				value={subHeader}
				normalStyle={style.bodyText}
				bulletStyle={style.bodyText}
				numberedStyle={style.bodyText}
				h1Style={style.bodyText}
				h2Style={style.bodyText}
				h3Style={style.bodyText}
				h4Style={style.bodyText}
				h5Style={style.bodyText}
				h6Style={style.bodyText}
				blockqouteStyle={style.bodyText}
			/>
			<div className={style.employeesContainer}>
				{employees.map((employee) => (
					<EmployeeView {...employee} />
				))}
			</div>
		</div>
	);
};

export default EmployeeSection;
