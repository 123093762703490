export const pageSchema = {
	name: "page",
	title: "Pages",
	type: "document",
	options: {
		// corrected from 'option' to 'options'
		source: "content",
	},
	fields: [
		{
			name: "pageTitle",
			title: "Page Title",
			type: "string",
		},
		{
			name: "slug",
			title: "Slug",
			type: "slug",
			options: {
				source: "pageTitle",
				maxLength: 96,
			},
		},
		{
			name: "headerImage",
			title: "Header/Background Image",
			type: "image", // ensure 'image' is used or define 'imageSchema' elsewhere
			options: {
				hotspot: true, // Optional: allows hotspot cropping if needed
			},
		},
		{
			name: "body",
			title: "Body",
			type: "basicSectionSchema",
		},
	],
};
