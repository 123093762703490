export const AboutUsPayload = `
*[_id == "fcefcac9-7de9-4de8-b195-ed5bb08a12b8"] {
  _id,
  headerImage {
   asset -> {
     _id,
     url 
   }
 },
 content,
 body,
}
 `;
