import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const sanityProjectID = "uix0br5w";
export const sanityDataset = "production";

const client = createClient({
	projectId: sanityProjectID, // find this at manage.sanity.io or in your sanity.json
	dataset: "production", // this is from those question during 'sanity init'
	useCdn: true,
	apiVersion: "2023-10-25",
});

// Set up a helper function for image URLs
const builder = imageUrlBuilder(client);

export const urlFor = (source: any) => {
	return builder.image(source);
};

export default client;
