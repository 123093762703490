import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import Client from "../../sanityClient";
import { FetchProjectsPayload } from "./interfaces";
import { ProjectData } from "./interfaces";

const fetchAllProjects = async () => {
	try {
		const result = await Client.fetch(FetchProjectsPayload);
		return (result as ProjectData[]) ?? undefined;
	} catch (error) {
		throw error;
	}
};

export const useProjects = () => {
	const { data: contentData, isLoading } = useQuery({
		queryKey: ["projects"],
		queryFn: fetchAllProjects,
	});

	return {
		contentData,
		isLoading,
	};
};
