import { NavLink } from "react-router-dom";
import { useWindowSize } from "../../modules/useWindowSize";
import { useFooter } from "./hooks";

import Button from "../button";
import PortableTextArea from "../portable-text";
import ContentImage from "../content-image";

// import * as main from "../../utils/main";
import style from "./style.module.css";
import { sanityDataset, sanityProjectID } from "../../sanityClient";
import { HeaderMenu } from "../../hooks/headerFooter/interfaces";

const Footer = () => {
	const { isMobileWidth } = useWindowSize();
	const {
		navigateEstimate,
		facebookLink,
		tiktokLink,
		instagramLink,
		menus,
		freeEstimateLabel,
		footerLabel,
		logoImage,
		goingHome,
	} = useFooter();
	// const menuItemStyle = [main.font.menu, style.menuLink].join(" ");
	// const menuItemStyleActive = [main.font.menu, style.menuLinkSelected].join(
	// 	" "
	// );
	// const foterStyle = [main.font.footNote, style.footNote].join(" ");

	const _buildMenuItem = (item: HeaderMenu) => {
		return (
			<li className={style.menuList} key={`menu-${item.menuName}`}>
				<NavLink
					className={({ isActive }) =>
						isActive ? style.menuLinkSelected : style.menuLink
					}
					to={item.menuURL}
				>
					{item.menuName}
				</NavLink>
			</li>
		);
	};

	const _buildSocialLinks = () => {
		return (
			<div className={style.socialLinkContainer}>
				{facebookLink && (
					<a href={facebookLink.socialURL}>
						<ContentImage
							id={facebookLink.socialIcon.asset._ref}
							alt={facebookLink.socialIcon.altText}
							style={style.socialIcon}
						/>
					</a>
				)}
				{tiktokLink && (
					<a href={tiktokLink.socialURL}>
						<ContentImage
							id={tiktokLink.socialIcon.asset._ref}
							alt={tiktokLink.socialIcon.altText}
							style={style.socialIcon}
						/>
					</a>
				)}
				{instagramLink && (
					<a href={instagramLink.socialURL}>
						<ContentImage
							id={instagramLink.socialIcon.asset._ref}
							alt={instagramLink.socialIcon.altText}
							style={style.socialIcon}
						/>
					</a>
				)}
			</div>
		);
	};

	const _buildDesktopView = () => {
		return (
			<div className={style.mainContainer}>
				{logoImage && (
					<ContentImage
						id={logoImage?.asset._ref}
						alt={logoImage.altText}
						style={style.gdLogo}
						onClick={() => goingHome()}
					/>
				)}
				<div className={style.separatorLine} />
				<div className={style.menus}>
					{menus.map((item) => _buildMenuItem(item))}
				</div>
				<div className={style.footNoteContainer}>
					{footerLabel && (
						<PortableTextArea
							value={footerLabel}
							normalStyle={style.footNote}
						/>
					)}
				</div>
				<div className={style.parentSocialContainer}>
					{_buildSocialLinks()}
					<Button
						title={freeEstimateLabel}
						titleStyles={style.estimateTitle}
						containerStyles={style.estimateContainer}
						onClick={navigateEstimate}
					/>
				</div>
			</div>
		);
	};

	const _buildMobileView = () => {
		return (
			<div className={style.mainContainer}>
				<div className={style.logoMenuContainer}>
					{logoImage && (
						<ContentImage
							id={logoImage?.asset._ref}
							alt={logoImage.altText}
							style={style.gdLogo}
							onClick={() => goingHome()}
						/>
					)}
					<div className={style.menus}>
						{menus.map((item) => _buildMenuItem(item))}
					</div>
				</div>

				<div className={style.footNoteContainer}>
					{footerLabel && (
						<PortableTextArea
							value={footerLabel}
							normalStyle={style.footNote}
						/>
					)}
				</div>
				<div className={style.parentSocialContainer}>
					{_buildSocialLinks()}
					<Button
						title={freeEstimateLabel}
						titleStyles={style.estimateTitle}
						containerStyles={style.estimateContainer}
						onClick={navigateEstimate}
					/>
				</div>
			</div>
		);
	};

	return isMobileWidth ? _buildMobileView() : _buildDesktopView();
};

export default Footer;
