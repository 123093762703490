export const BlogDetailedPayload = `*[_type == 'blogSchema' && _id == $id] {
  _id,
  _createdAt,
  _updatedAt,
  header,
  tagline,
  headerImage {
    asset -> {
      _id,
      _ref,
      url 
    }
  },
  contents[] {
    ...,
    // For images within the imagesArray
    _type == "imagesArray" => {
      ...,
      "images": images[] {
        altText,
        asset -> {
          _id,
          url 
        }
      }
    }
  }
}`;
