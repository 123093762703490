export const ProjectDetailedPayload = `*[_type == 'projectSchema' && _id == $id] {
  headerTitle {
    headerTitle,
    subHeaderTitle,
  },
  shortDescription,
  backgroundImage {
    asset -> {
      _id,
      url
    }
  },
}`;
