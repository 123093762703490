export const employeeSchema = {
	name: "employeeSchema",
	title: "Team",
	type: "object",
	fields: [
		{
			name: "name",
			title: "Name",
			type: "string",
		},
		{
			name: "position",
			title: "Position or Title",
			type: "string",
		},
		{
			name: "profilePhoto",
			title: "Photo",
			type: "imageSchema",
		},
	],
};

export const teamSchema = {
	name: "teamSchema",
	title: "Team",
	type: "object",
	fields: [
		{
			name: "header",
			title: "Header Title",
			type: "string",
		},
		{
			name: "description",
			title: "Brief Description",
			type: "array",
			of: [{ type: "block" }],
		},
		{
			name: "employees",
			title: "Employees",
			type: "array",
			of: [{ type: "employeeSchema" }],
		},
	],
};
