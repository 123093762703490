export const basicSectionSchema = {
	name: "basicSectionSchema",
	title: "Basic Section",
	type: "object",
	fields: [
		{
			name: "headerTitle",
			title: "Header",
			type: "string",
		},
		{
			name: "subHeaderTitle",
			title: "Sub Header",
			type: "string",
		},
		{
			name: "content",
			title: "Content Body",
			type: "array",
			of: [
				{
					name: "paragraph",
					title: "Paragraph",
					type: "block",
				},
				{
					name: "featuredBullet",
					title: "Featured Bullet",
					type: "featuredSectionBulletSchema",
				},
				{
					name: "featuredList",
					title: "Featured List",
					type: "featuredSectionListSchema",
				},
				{
					name: "featuredProjects",
					title: "Featured Projects",
					type: "featuredProjectsSchema",
				},
				{
					name: "featuredBlogs",
					title: "Featured Blogs",
					type: "featuredBlogPostSchema",
				},
				{
					name: "projects",
					title: "Projects",
					type: "reference",
					to: [{ type: "projectSchema" }],
				},
				{
					name: "imagesArray",
					title: "Group Images",
					type: "groupImages",
				},
				{
					name: "cta",
					title: "CTA",
					type: "ctaSchema",
				},
				{
					name: "teams",
					title: "Teams",
					type: "teamSchema",
				},
				{
					name: "initialEstimates",
					title: "Initial Estimate",
					type: "estimageFormSectionSchema",
				},
				{
					name: "contactUsForm",
					title: "Contact Us Form",
					type: "contactFormSectionSchema",
				},
			],
		},
	],
};
