import { useWindowSize } from "../../modules/useWindowSize";
import { useMemo } from "react";
import { urlFor } from "../../sanityClient";

import { ReactPhotoCollage } from "react-photo-collage";

import style from "./style.module.css";
import { GroupImage } from "../../interfaces/groupImages";

const GroupImagesCollage: React.FC<GroupImage> = ({ images }) => {
	const { windowSize, isMobileWidth } = useWindowSize();
	const photos = images.map((image) => {
		return { source: urlFor(image).url() };
	});

	const firstHeight = isMobileWidth ? "400px" : "550px";
	const secondHeight = isMobileWidth ? "200px" : "330px";

	const layout = useMemo(() => {
		switch (images.length) {
			case 1:
				return [1];
			case 2:
				return [1, 1];
			case 3:
				return [1, 2];
			default:
				return [1, 3];
		}
	}, [images]);

	return (
		<div className={style.collageContainer}>
			<ReactPhotoCollage
				layout={layout}
				photos={photos}
				showNumOfRemainingPhotos
				height={[firstHeight, secondHeight]}
				width={`${windowSize.width}px`}
			/>
		</div>
	);
};

export default GroupImagesCollage;
