import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useHeaderFooterStore } from "../../hooks/headerFooter";
import { HeaderMenu } from "../../hooks/headerFooter/interfaces";

export const useMenu = () => {
	const { contentData } = useHeaderFooterStore();
	const [isMenuActive, setMenuState] = useState<boolean>(false);
	const navigate = useNavigate();

	const desktopMenus = useMemo(() => {
		const fetchedMenus: (HeaderMenu | undefined)[] =
			[...(contentData?.headerMenus ?? [])] ?? [];

		if (fetchedMenus && fetchedMenus?.length % 2 === 0) {
			const middleIndex = fetchedMenus.length / 2;
			fetchedMenus.splice(middleIndex, 0, undefined);
		}

		return fetchedMenus;
	}, [contentData]);

	const mobileMenus = useMemo(() => {
		return contentData?.headerMenus ?? [];
	}, [contentData]);

	const logo = useMemo(() => {
		return contentData?.headerSiteLogo;
	}, [contentData]);

	const goingHome = () => {
		navigate("/");
	};

	return {
		desktopMenus,
		mobileMenus,
		isMenuActive,
		setMenuState,
		goingHome,
		logo,
	};
};
